import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['rank'])
}
const _hoisted_2 = { class: "token-name" }



import { ROUTE_SALE } from "@/utils/constants";
import { TokenDto } from "@/api/generated";
import { useLiveTokenData } from "@/composables";

import PriceDataFormatter from "@/components/Common/Pricing/PriceDataFormatter.vue";
import TokenLineChart from "@/components/Charts/TokenLineChart.vue";
import AddressChip from "@/components/Common/AddressChip.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileTokenSaleCard',
  props: {
    token: {}
  },
  setup(__props: any) {

const props = __props;

const { tokenData } = useLiveTokenData({
  token: props.token,
});

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.token?.sale_address)
    ? (_openBlock(), _createBlock(_component_v_card, {
        key: 0,
        block: "",
        flat: "",
        class: "px-2 py-4 token-card",
        to: {
      name: _unref(ROUTE_SALE),
      params: { contractAddress: _ctx.token.sale_address },
    }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "2",
                class: "py-1"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _cache[0] || (_cache[0] = _createTextVNode(" # ")),
                    _createElementVNode("b", null, _toDisplayString(_ctx.token.rank), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "10",
                sm: "7",
                class: "py-1 pl-0"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.token.symbol), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "3",
                class: "py-1 pl-0 hidden-xs text-right"
              }, {
                default: _withCtx(() => [
                  _createVNode(AddressChip, {
                    address: _ctx.token.sale_address
                  }, null, 8, ["address"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { align: "center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "8",
                offset: "2",
                class: "pl-0 py-1"
              }, {
                default: _withCtx(() => [
                  (_ctx.token.price)
                    ? (_openBlock(), _createBlock(_component_v_row, {
                        key: 0,
                        class: "pricing"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "3",
                            class: "pr-0 py-1"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("common.price")) + ":", 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "9",
                            class: "py-1"
                          }, {
                            default: _withCtx(() => [
                              (_unref(tokenData)?.price_data)
                                ? (_openBlock(), _createBlock(PriceDataFormatter, {
                                    key: 0,
                                    row: "",
                                    "price-data": _unref(tokenData).price_data
                                  }, null, 8, ["price-data"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "3",
                            class: "pr-0 py-1"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("common.marketCapShort")) + ": ", 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "9",
                            class: "py-1"
                          }, {
                            default: _withCtx(() => [
                              (_unref(tokenData)?.market_cap_data)
                                ? (_openBlock(), _createBlock(PriceDataFormatter, {
                                    key: 0,
                                    row: "",
                                    bignumber: "",
                                    "price-data": _unref(tokenData).market_cap_data
                                  }, null, 8, ["price-data"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_v_skeleton_loader, {
                        key: 1,
                        type: "chip"
                      }))
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "2",
                class: "px-0 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(TokenLineChart, {
                    "sale-address": _ctx.token.address,
                    "chart-type": "line",
                    height: 50
                  }, null, 8, ["sale-address"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"]))
    : _createCommentVNode("", true)
}
}

})