// Styles
import "@mdi/font/css/materialdesignicons.css";
import "@/styles/main.scss";

import { createVuetify } from "vuetify";
import { SETTINGS } from "@/utils/constants";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: SETTINGS.vuetify.theme,
});
