import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pb-2 d-flex ga-2 align-center token-list-header" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_4 = {
  key: 0,
  class: "loading-layer d-flex justify-center"
}
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { style: {"width":"87px"} }
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { class: "text-center" }

import { computed, onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { useInfiniteQuery } from "@tanstack/vue-query";
import { useI18n } from "vue-i18n";

import type { TokenCategory } from "@/utils/types";
import { TokensService } from "@/api/generated";
import WebSocketClient from "@/libs/WebSocketClient";
import { useNetwork } from "@/composables";
import { useAccounts } from "@/stores/accounts";

import MobileTokenSaleCard from "@/components/Tokens/TokenSaleCard/MobileTokenSaleCard.vue";
import TokenSaleCard from "@/components/Tokens/TokenSaleCard/TokenSaleCard.vue";
import WalletConnectCard from "@/components/WalletConnect/WalletConnectCard.vue";
import LatestTransactionsCarousel from "@/components/LatestTransactionsCarousel.vue";
import AppTooltip from "@/components/Common/AppTooltip.vue";

type TokenCategoryOption = TokenCategory | "all";

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const { smAndDown, mdAndDown } = useDisplay();
const { activeNetworkId } = useNetwork();
const { t } = useI18n();
const { activeAccount } = storeToRefs(useAccounts());
const route = useRoute();
const router = useRouter();

const queryFilters = computed(() => ({
  sort: route.query.sort ?? "market_cap",
  dir: route.query.dir ?? "DESC",
  category: route.query.category ?? "all",
}));

function updateQueryFilters(query: Record<string, string>) {
  router.push({
    query: {
      ...route.query,
      ...query,
    },
  });
}

// Filters
const filterForCurrentAccount = ref(false);
const search = ref<string>();
const sortOptions = [
  {
    title: t("common.name"),
    value: "name",
  },
  {
    title: t("common.marketCap"),
    value: "market_cap",
  },
  {
    title: t("common.rank"),
    value: "rank",
  },
  {
    title: t("common.creationDate"),
    value: "created_at",
  },
];

const categoryOptions: {
  title: string;
  value: TokenCategoryOption;
  disabled?: boolean;
}[] = [
  {
    title: t("common.all"),
    value: "all",
  },
  {
    title: t("categories.words.title"),
    value: "word",
  },
  {
    title: t("categories.numbers.title"),
    value: "number",
  },
  // {
  //   title: t("categories.词.title"),
  //   value: "词",
  //   disabled: true,
  // },
  // {
  //   title: t("categories.ideas.title"),
  //   value: "idea",
  //   disabled: true,
  // },
  // {
  //   title: t("categories.images.title"),
  //   value: "image",
  //   disabled: true,
  // },
];
const selectedCategory = computed(() =>
  categoryOptions.find((option) => option.value === queryFilters.value.category)
);
const activeSortOption = computed(() =>
  sortOptions.find((option) => option.value === queryFilters.value.sort)
);
const sortDirectionOptions = [
  {
    title: t("common.ascending"),
    value: "ASC",
  },
  {
    title: t("common.descending"),
    value: "DESC",
  },
];
const scrollComponent = ref<HTMLElement>();
const newTokenListenerSubscription = ref();
let refetchTimeout: NodeJS.Timeout;

const mappedSortMode = computed(() => {
  if (queryFilters.value.category === "all") {
    return queryFilters.value.sort;
  }
  if (queryFilters.value.sort !== "rank") {
    return queryFilters.value.sort;
  }
  return "category_rank";
});

const { data, isFetching, fetchNextPage, hasNextPage, refetch } =
  useInfiniteQuery({
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      TokensService.listAll({
        orderBy: mappedSortMode.value as any,
        orderDirection: queryFilters.value.dir as any,
        category: queryFilters.value.category as any,
        search: search.value,
        ownerAddress: filterForCurrentAccount.value
          ? (activeAccount.value as string)
          : undefined,
        limit: 50,
        page: pageParam,
      }),
    getNextPageParam: (lastPage: any, allPages, lastPageParam) => {
      if (lastPage?.meta?.currentPage === lastPage?.meta?.totalPages) {
        return undefined;
      }

      return lastPageParam + 1;
    },
    queryKey: [
      "TokensService.listAll",
      queryFilters,
      mappedSortMode,
      search,
      activeNetworkId,
      filterForCurrentAccount,
      activeAccount,
    ],
  });

const handleScroll = () => {
  if (
    (hasNextPage.value &&
      scrollComponent.value?.getBoundingClientRect()?.bottom) ||
    0 < window.innerHeight
  ) {
    fetchNextPage();
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  newTokenListenerSubscription.value = WebSocketClient.subscribeToNewTokenSales(
    (token: any) => {
      console.log("New token sale::", token);
      clearTimeout(refetchTimeout);
      refetchTimeout = setTimeout(() => {
        refetch();
      }, 4000);
    }
  );
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  newTokenListenerSubscription.value?.();
});

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(WalletConnectCard),
    _createElementVNode("div", {
      class: "home-view container",
      ref_key: "scrollComponent",
      ref: scrollComponent
    }, [
      _createVNode(LatestTransactionsCarousel),
      _createVNode(_component_v_row, {
        class: "mt-4",
        align: "center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: _unref(activeAccount) ? 8 : 12,
            lg: "2",
            class: "py-1"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h2", {
                  textContent: _toDisplayString(_ctx.$t('common.tokenList')),
                  class: "text-h4"
                }, null, 8, _hoisted_2)
              ])
            ]),
            _: 1
          }, 8, ["md"]),
          (_unref(activeAccount))
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                cols: "12",
                md: "4",
                lg: "2",
                class: "py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_switch, {
                    modelValue: filterForCurrentAccount.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterForCurrentAccount).value = $event)),
                    label: _ctx.$t('views.home.showOwnedOnly'),
                    color: "primary",
                    "hide-details": ""
                  }, null, 8, ["modelValue", "label"])
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_v_spacer, { key: 1 })),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "3",
            lg: "2",
            class: "py-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_menu, null, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({ variant: "outlined" }, props, {
                    block: "",
                    height: "42px"
                  }), {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("common.categories")) + ": " + _toDisplayString(selectedCategory.value?.title) + " ", 1),
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("mdi-chevron-down")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1040)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(categoryOptions, (item, index) => {
                        return _createVNode(_component_v_list_item, {
                          key: index,
                          disabled: item.disabled,
                          onClick: ($event: any) => (updateQueryFilters({ category: item.value }))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, {
                              class: _normalizeClass({
                  'text-primary': queryFilters.value.category == item.value,
                })
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.title) + " ", 1),
                                (item.disabled)
                                  ? (_openBlock(), _createElementBlock("small", _hoisted_3, " (" + _toDisplayString(_ctx.$t("common.comingSoon")) + ") ", 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["class"])
                          ]),
                          _: 2
                        }, 1032, ["disabled", "onClick"])
                      }), 64))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "3",
            lg: "2",
            class: "py-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_menu, null, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({ variant: "outlined" }, props, {
                    block: "",
                    height: "42px"
                  }), {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("common.sort")) + ": " + _toDisplayString(activeSortOption.value?.title) + " ", 1),
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("mdi-chevron-down")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1040)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(sortOptions, (item, index) => {
                        return _createVNode(_component_v_list_item, {
                          key: index,
                          onClick: ($event: any) => (
                updateQueryFilters({
                  sort: item.value,
                })
              )
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, {
                              class: _normalizeClass({
                  'text-primary': queryFilters.value.sort == item.value,
                })
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.title), 1)
                              ]),
                              _: 2
                            }, 1032, ["class"])
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      }), 64))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "3",
            lg: "2",
            class: "py-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_menu, null, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({ variant: "outlined" }, props, {
                    block: "",
                    height: "42px"
                  }), {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("common.order")) + ": " + _toDisplayString(queryFilters.value.dir) + " ", 1),
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("mdi-chevron-down")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1040)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(sortDirectionOptions, (item, index) => {
                        return _createVNode(_component_v_list_item, {
                          key: index,
                          onClick: ($event: any) => (updateQueryFilters({ dir: item.value }))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, {
                              class: _normalizeClass({
                  'text-primary': queryFilters.value.dir == item.value,
                })
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.title), 1)
                              ]),
                              _: 2
                            }, 1032, ["class"])
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      }), 64))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "3",
            lg: "2",
            class: "py-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: search.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((search).value = $event)),
                label: _ctx.$t('views.home.searchLabel'),
                variant: "outlined",
                "append-inner-icon": "mdi-magnify",
                density: "compact",
                "hide-details": "",
                "single-line": ""
              }, null, 8, ["modelValue", "label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "position-relative" }, {
        default: _withCtx(() => [
          (_unref(isFetching))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_v_progress_circular, {
                  indeterminate: "",
                  color: "primary",
                  size: "80"
                })
              ]))
            : _createCommentVNode("", true),
          (
          (!_unref(data)?.pages?.length || !_unref(data)?.pages[0].items.length) && !_unref(isFetching)
        )
            ? (_openBlock(), _createBlock(_component_v_alert, {
                key: 1,
                textContent: _toDisplayString(_ctx.$t('views.home.noTokenSales')),
                type: "info",
                outlined: "",
                class: "ma-3"
              }, null, 8, ["textContent"]))
            : _createCommentVNode("", true),
          (!_unref(smAndDown))
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 2,
                cols: "12",
                md: "12",
                class: "pb-0 pt-8"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "table-title" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6",
                        md: "4",
                        lg: "4",
                        xl: "4"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                              _createVNode(AppTooltip, null, {
                                activator: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("common.rank")), 1)
                                ]),
                                default: _withCtx(() => [
                                  _cache[5] || (_cache[5] = _createTextVNode(" Ranking is based on the market cap of the token. "))
                                ]),
                                _: 1
                              })
                            ]),
                            _createElementVNode("div", {
                              textContent: _toDisplayString(_ctx.$t('common.tokenName'))
                            }, null, 8, _hoisted_7)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "1",
                        md: "1",
                        lg: "1",
                        class: "text-right"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(AppTooltip, { end: "" }, {
                            activator: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("common.category")), 1)
                            ]),
                            default: _withCtx(() => [
                              _cache[6] || (_cache[6] = _createTextVNode(" Category of the token. "))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "1",
                        md: "2",
                        lg: "1",
                        class: "text-right"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("common.price")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "1",
                        md: "2",
                        lg: "1",
                        class: "text-right"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("common.marketCap")), 1)
                        ]),
                        _: 1
                      }),
                      (!_unref(mdAndDown))
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 0,
                            cols: "3",
                            md: "2",
                            lg: "2",
                            xl: "1",
                            class: "px-1 text-center"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("common.contractAddress")), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_col, {
                        cols: "1",
                        class: "px-1 text-center"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("common.performance")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_unref(data)?.pages)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_unref(data).pages, (page) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (page?.items)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(page.items, (token) => {
                        return (_openBlock(), _createBlock(_component_v_col, {
                          key: token.sale_address,
                          cols: "12",
                          md: "12",
                          xl: "12",
                          class: "py-1"
                        }, {
                          default: _withCtx(() => [
                            (_unref(smAndDown))
                              ? (_openBlock(), _createBlock(MobileTokenSaleCard, {
                                  key: 0,
                                  token: token
                                }, null, 8, ["token"]))
                              : (_openBlock(), _createBlock(TokenSaleCard, {
                                  key: 1,
                                  token: token,
                                  "use-category-rank": queryFilters.value.category !== 'all'
                                }, null, 8, ["token", "use-category-rank"]))
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    : _createCommentVNode("", true)
                ], 64))
              }), 256))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_8, [
        (_unref(hasNextPage))
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              textContent: _toDisplayString(_ctx.$t('common.loadMore')),
              onClick: _unref(fetchNextPage),
              variant: "outlined",
              rounded: "",
              size: "large",
              loading: _unref(isFetching)
            }, null, 8, ["textContent", "onClick", "loading"]))
          : _createCommentVNode("", true)
      ])
    ], 512)
  ], 64))
}
}

})