import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-none d-lg-block ml-2" }

import { useNetwork } from "@/composables";
import { NETWORKS } from "@/utils/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'NetworkSelector',
  setup(__props) {

const { activeNetwork, changeActiveNetwork } = useNetwork();

const networkChangeAllowed = Object.keys(NETWORKS).length > 1;

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, {
    disabled: !networkChangeAllowed
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps(props, {
        variant: "text",
        class: "active-network bcl-app-header-options",
        title: _ctx.$t('common.network'),
        readonly: !networkChangeAllowed
      }), {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            color: "green",
            size: "14"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("mdi-circle")
            ])),
            _: 1
          }),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(activeNetwork).name), 1),
          networkChangeAllowed
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 0,
                class: "mr-n1"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" mdi-chevron-down ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1040, ["title", "readonly"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(NETWORKS), (network) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: network.networkId
            }, [
              (network.networkId !== _unref(activeNetwork).networkId)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 0,
                    value: network.networkId,
                    onClick: ($event: any) => (_unref(changeActiveNetwork)(network.networkId))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(network.name), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["value", "onClick"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["disabled"]))
}
}

})