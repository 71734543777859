import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "d-flex ga-4 mb-4" }
const _hoisted_5 = ["textContent"]

import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify/lib/framework.mjs";

import { SETTINGS } from "@/utils/constants";

import OrderedListItem from "@/components/Common/OrderedListItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HowItWorksDialog',
  setup(__props) {

const { smAndDown } = useDisplay();
const { t } = useI18n();

const showDialog = ref(false);

const listItems = [
  {
    title: t("blocks.howItWorks.listItems.item1.title"),
    desc: t("blocks.howItWorks.listItems.item1.desc"),
  },
  {
    title: t("blocks.howItWorks.listItems.item2.title"),
    desc: t("blocks.howItWorks.listItems.item2.desc"),
  },
  {
    title: t("blocks.howItWorks.listItems.item3.title"),
    desc: t("blocks.howItWorks.listItems.item3.desc"),
  },
  {
    title: t("blocks.howItWorks.listItems.item4.title"),
    desc: t("blocks.howItWorks.listItems.item4.desc"),
  },
  {
    title: t("blocks.howItWorks.listItems.item5.title"),
    desc: t("blocks.howItWorks.listItems.item5.desc"),
  },
  {
    title: t("blocks.howItWorks.listItems.item6.title"),
    desc: t("blocks.howItWorks.listItems.item6.desc"),
  },
];

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_btn, {
      color: "primary",
      variant: "text",
      size: _unref(smAndDown) ? 'small' : undefined,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showDialog.value = true))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("blocks.howItWorks.title")), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    _createVNode(_component_v_dialog, {
      modelValue: showDialog.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((showDialog).value = $event)),
      "max-width": "600px",
      fullscreen: _unref(smAndDown)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, {
              textContent: _toDisplayString(_ctx.$t('blocks.howItWorks.title'))
            }, null, 8, ["textContent"]),
            _createVNode(_component_v_card_text, { class: "px-4" }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  textContent: _toDisplayString(_ctx.$t('blocks.howItWorks.intro')),
                  class: "font-weight-black mb-6"
                }, null, 8, _hoisted_1),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(listItems, ({ title, desc }, index) => {
                  return _createVNode(OrderedListItem, {
                    key: index,
                    index: index + 1
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        textContent: _toDisplayString(title),
                        class: "text-h5"
                      }, null, 8, _hoisted_2),
                      _createElementVNode("div", {
                        textContent: _toDisplayString(desc),
                        class: "text-body-1 flex-wrap"
                      }, null, 8, _hoisted_3)
                    ]),
                    _: 2
                  }, 1032, ["index"])
                }), 64)),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    textContent: _toDisplayString(
                _ctx.$t('blocks.howItWorks.outro', { appName: _unref(SETTINGS).app.name })
              ),
                    class: "text-body-1"
                  }, null, 8, _hoisted_5)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  flat: "",
                  color: "primary",
                  variant: "outlined",
                  size: "large",
                  class: "px-12",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (showDialog.value = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("common.close")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "fullscreen"])
  ]))
}
}

})