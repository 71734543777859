<template>
  <v-menu max-height="400px">
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        variant="text"
        class="header-currency-selector bcl-app-header-options"
      >
        <span class="text-uppercase">{{ currentCurrencyCode }}</span>
        <v-icon class="mr-n1">mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-list>
      <template v-for="currency in CURRENCIES" :key="currency">
        <v-list-item
          v-if="currentCurrencyCode !== currency.code"
          :value="currency"
          @click="setCurrentCurrency(currency.code)"
        >
          <v-list-item-title>
            {{ currency.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { useCurrencies } from "@/composables";
import { CURRENCIES } from "@/utils/constants";

const { setCurrentCurrency, currentCurrencyCode } = useCurrencies();
</script>
