import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "mx-1 is-grey" }

import { ref } from "vue";
import "vue3-carousel/dist/carousel.css";
// https://ismail9k.github.io/vue3-carousel/examples.html
import { Carousel, Slide } from "vue3-carousel";

import type { ITransaction } from "@/utils/types";
import { TX_FUNCTIONS } from "@/utils/constants";
import { useAeScan } from "@/composables";
import AddressChip from "@/components/Common/AddressChip.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LatestTransactionsCarousel',
  setup(__props) {

const { prepareUrlForHash } = useAeScan();

const latestTransactionRef = ref<HTMLElement>();
const carouselRef = ref(null);

const latestTransactions = ref<ITransaction[]>([]);

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_card = _resolveComponent("v-card")!

  return (latestTransactions.value?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-h4 pb-2" }, [
          _createElementVNode("div", null, "Latest Trades")
        ], -1)),
        _createVNode(_unref(Carousel), {
          ref_key: "carouselRef",
          ref: carouselRef,
          options: { currentPage: 0 },
          "snap-align": "start",
          autoplay: 1000,
          "pause-autoplay-on-hover": "",
          "items-to-show": 4,
          breakpoints: {
        1920: { itemsToShow: 4 },
        1280: { itemsToShow: 3 },
        600: { itemsToShow: 2 },
        100: { itemsToShow: 1 },
      }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(latestTransactions.value, (transaction) => {
              return (_openBlock(), _createBlock(_unref(Slide), {
                key: transaction.hash
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    id: "latestTransactionRef",
                    ref_for: true,
                    ref_key: "latestTransactionRef",
                    ref: latestTransactionRef,
                    variant: "outlined",
                    block: "",
                    style: {"width":"100%"},
                    class: "mr-2 border-md pa-2 d-flex align-center justify-space-between latest-trades-item"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(AddressChip, {
                          address: transaction.tx.callerId
                        }, null, 8, ["address"]),
                        _createElementVNode("span", _hoisted_3, _toDisplayString(transaction.tx.function === _unref(TX_FUNCTIONS).sell
                  ? "sold"
                  : "bought"), 1),
                        _cache[0] || (_cache[0] = _createElementVNode("strong", { class: "amount" }, " {AMOUNT} ", -1)),
                        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "mx-1 is-grey" }, "of", -1)),
                        _cache[2] || (_cache[2] = _createElementVNode("strong", { class: "symbol" }, " {symbol} ", -1))
                      ]),
                      _createVNode(_component_v_chip, {
                        href: _unref(prepareUrlForHash)(transaction.hash),
                        target: "_blank"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_avatar, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode("mdi-open-in-new")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["href"])
                    ]),
                    _: 2
                  }, 1536)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 512)
      ]))
    : _createCommentVNode("", true)
}
}

})