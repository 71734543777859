<template>
  <div v-if="latestTransactions?.length">
    <div class="text-h4 pb-2">
      <div>Latest Trades</div>
    </div>
    <Carousel
      ref="carouselRef"
      :options="{ currentPage: 0 }"
      snap-align="start"
      :autoplay="1000"
      pause-autoplay-on-hover
      :items-to-show="4"
      :breakpoints="{
        1920: { itemsToShow: 4 },
        1280: { itemsToShow: 3 },
        600: { itemsToShow: 2 },
        100: { itemsToShow: 1 },
      }"
    >
      <Slide v-for="transaction in latestTransactions" :key="transaction.hash">
        <v-card
          id="latestTransactionRef"
          ref="latestTransactionRef"
          variant="outlined"
          block
          style="width: 100%"
          class="mr-2 border-md pa-2 d-flex align-center justify-space-between latest-trades-item"
        >
          <div class="d-flex align-center">
            <AddressChip :address="transaction.tx.callerId" />

            <span class="mx-1 is-grey">
              {{
                transaction.tx.function === TX_FUNCTIONS.sell
                  ? "sold"
                  : "bought"
              }}</span
            ><strong class="amount"> {AMOUNT} </strong>
            <span class="mx-1 is-grey">of</span>
            <strong class="symbol"> {symbol} </strong>
          </div>

          <v-chip :href="prepareUrlForHash(transaction.hash)" target="_blank">
            <v-avatar>
              <v-icon>mdi-open-in-new</v-icon>
            </v-avatar>
          </v-chip>
        </v-card>
      </Slide>
    </Carousel>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import "vue3-carousel/dist/carousel.css";
// https://ismail9k.github.io/vue3-carousel/examples.html
import { Carousel, Slide } from "vue3-carousel";

import type { ITransaction } from "@/utils/types";
import { TX_FUNCTIONS } from "@/utils/constants";
import { useAeScan } from "@/composables";
import AddressChip from "@/components/Common/AddressChip.vue";

const { prepareUrlForHash } = useAeScan();

const latestTransactionRef = ref<HTMLElement>();
const carouselRef = ref(null);

const latestTransactions = ref<ITransaction[]>([]);
</script>

<style lang="scss" scoped>
@use "@/styles/variables" as *;

.text-h4 {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 88.889% */
  letter-spacing: 1.44px !important;
  text-transform: uppercase;
  display: flex;
  padding: 8px 12px 8px 0px;
  margin-bottom: 12px;
  align-items: center;
  gap: 8px;
}

.latest-trades-item {
  border: 0px !important;
  border-radius: 2px;
}

.amount {
  font-weight: 500;
}

.is-grey {
  opacity: 0.8;
}

.symbol {
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 16px */
  letter-spacing: 0.72px !important;
}
</style>
