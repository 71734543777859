<template>
  <div
    :class="[
      'price-formatter',
      {
        'text-red': priceJustDecreased,
        'text-green': priceJustIncreased,
        'd-flex align-center ga-1': row,
      },
    ]"
  >
    <div class="price d-flex align-center" v-bind="$attrs">
      <v-icon v-if="priceJustIncreased">mdi-trending-up</v-icon>
      <v-icon v-if="priceJustDecreased">mdi-trending-down</v-icon>
      <span v-if="false">{{ aePrice?.prettify(6) }}//</span>
      <FractionFormatter :fractional-price="formatFractionalPrice(aePrice)" />
      <v-progress-circular v-if="priceLoading" indeterminate size="15" />
      <span v-else-if="!hideSymbol" class="pl-1">{{ symbol }}</span>
    </div>
    <div
      v-if="!hideFiatPrice"
      :class="['fiat d-flex align-center ga-1', { 'justify-end': row }]"
      v-bind="$attrs"
    >
      <div>{{ currentCurrencyInfo.symbol }}</div>
      <FractionFormatter :fractional-price="formatFractionalPrice(fiatPrice)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, PropType, ref, watch } from "vue";

import { useCurrencies } from "@/composables/useCurrencies";
import { Decimal } from "@/libs/decimal";
import { formatFractionalPrice } from "@/utils/common";
import { AETERNITY_SYMBOL } from "@/utils/constants";

import FractionFormatter from "./FractionFormatter.vue";

const props = defineProps({
  aePrice: {
    type: Object as PropType<Decimal>,
    required: true,
  },
  fiatPrice: {
    type: Object as PropType<Decimal>,
    required: true,
  },
  symbol: {
    type: String,
    default: AETERNITY_SYMBOL,
  },
  watchPrice: {
    type: Boolean,
    default: true,
  },
  priceLoading: Boolean,
  hideFiatPrice: Boolean,
  hideSymbol: Boolean,
  row: Boolean,
});

const { currentCurrencyInfo } = useCurrencies();

const priceJustIncreased = ref(false);
const priceJustDecreased = ref(false);

watch(
  () => props.aePrice,
  (value, prev) => {
    if (!value || !prev || !props.watchPrice) return;
    if (value.gte(prev)) {
      priceJustIncreased.value = true;
      setTimeout(() => {
        priceJustIncreased.value = false;
      }, 500);
    } else {
      priceJustDecreased.value = true;
      setTimeout(() => {
        priceJustDecreased.value = false;
      }, 500);
    }
  }
);
</script>

<style lang="scss" scoped>
.price-formatter {
  font-weight: 500;

  .fiat {
    opacity: 0.8;
    font-weight: 400;
  }
}
</style>
