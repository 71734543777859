<template>
  <v-card v-if="!!invitationCode" class="mb-2">
    <v-card-text>
      <template v-if="!isRevoking">
        <div
          class="text-h3 pb-4"
          v-text="$t('blocks.invitationCollect.header', { appName })"
        />
        <p v-text="$t('blocks.invitationCollect.desc1')" />
        <p v-text="$t('blocks.invitationCollect.desc2')" />
      </template>

      <p class="text-h4 py-4 d-flex ga-1 align-center flex-wrap">
        <i18n-t
          :keypath="
            isRevoking
              ? 'blocks.invitationCollect.revokeReward'
              : 'blocks.invitationCollect.claimReward'
          "
        >
          <LivePriceFormatter
            :ae-price="invitationAmount"
            :watch-price="false"
            :price-loading="loadingInvitation"
            class="text-primary"
            row
          />
        </i18n-t>
      </p>

      <div class="pb-4 d-flex ga-1 align-center flex-wrap">
        <i18n-t
          v-if="!isRevoking"
          keypath="blocks.invitationCollect.addressInvited"
        >
          <template #address>
            <AddressChip :address="invitationSender" />
          </template>
          <template #appName>{{ appName }}</template>
        </i18n-t>

        <i18n-t
          :keypath="
            activeAccount
              ? 'blocks.invitationCollect.mainMessageConnected'
              : 'blocks.invitationCollect.mainMessageNotConnected'
          "
        >
          <template #prize>
            <LivePriceFormatter
              :ae-price="invitationAmount"
              :watch-price="false"
              :price-loading="loadingInvitation"
              row
            />
          </template>
        </i18n-t>
      </div>

      <v-alert
        v-if="errorMessage"
        icon="$error"
        color="error"
        class="mb-5"
        border="start"
        :title="$t('common.oops')"
        :text="errorMessage"
      />
      <v-alert
        v-if="successMessage"
        icon="$success"
        color="success"
        class="mb-5"
        border="start"
        title="Success!"
        :text="successMessage"
      />

      <v-btn
        v-if="activeAccount"
        size="large"
        color="primary"
        :disabled="invitationClaimed"
        :loading="claimingReward"
        @click="claimOrRevokeReward()"
      >
        {{
          isRevoking
            ? $t("blocks.invitationCollect.ctaRevoke")
            : $t("blocks.invitationCollect.ctaClaim")
        }}
      </v-btn>

      <WalletConnectBtn
        :label="$t('blocks.invitationCollect.connectWallet')"
        size="large"
      />

      <v-btn
        v-if="activeAccount"
        v-text="$t('common.dismiss')"
        size="large"
        color="primary"
        variant="outlined"
        class="ml-4"
        @click="onClose()"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { initCommunityFactory } from "token-gating-sdk";
import {
  AeSdk,
  AeSdkAepp,
  CompilerHttp,
  MemoryAccount,
} from "@aeternity/aepp-sdk";

import { SETTINGS } from "@/utils/constants";
import { Decimal } from "@/libs/decimal";
import { useAccounts } from "@/stores/accounts";
import {
  useAeppSdk,
  useInvitation,
  useNetwork,
  useTokenSaleFactory,
} from "@/composables";

import WalletConnectBtn from "@/components/WalletConnect/WalletConnectBtn.vue";
import AddressChip from "@/components/Common/AddressChip.vue";
import LivePriceFormatter from "@/components/Common/Pricing/LivePriceFormatter.vue";

const appName = SETTINGS.app.name;

const router = useRouter();

const { getAeSdk, nodes } = useAeppSdk();
const { activeNetwork } = useNetwork();
const { activeAccount } = storeToRefs(useAccounts());
const { activeTokenSaleFactoryAddress } = useTokenSaleFactory();
const { invitationCode } = useInvitation();

const invitationAmount = ref<Decimal>(Decimal.ZERO);
const invitationSender = ref();
const invitationClaimed = ref(false);

const loadingInvitation = ref(false);
const claimingReward = ref(false);

const errorMessage = ref();
const successMessage = ref();

const isRevoking = computed(
  () => invitationSender.value === activeAccount.value
);

watch(
  invitationCode,
  (invCode) => {
    if (invCode) {
      getInvitationRewardAmount();
    }
  },
  {
    immediate: true,
  }
);

watch(activeNetwork, () => {
  if (invitationCode.value) {
    getInvitationRewardAmount();
  }
});

/**
 * Retrieves the affiliation contract from the room factory.
 *
 * @param {AeSdk | AeSdkAepp} sdk - The AeSdk or AeSdkAepp instance.
 * @returns {Promise<AffiliationTreasury>} The affiliation treasury instance.
 */
async function getAffiliationTreasury(sdk: AeSdk | AeSdkAepp) {
  const factory = await initCommunityFactory(
    sdk,
    activeTokenSaleFactoryAddress.value
  );

  return await factory.affiliationTreasury();
}

/**
 * Retrieves the invitation reward amount for a given invitation code.
 * This function sets the loading state, initializes the AeSdk, retrieves the affiliation contract,
 * and updates the invitation details if the invitation code exists.
 * If the invitation has already been claimed, it displays an error message and closes the component after 3 seconds.
 * @throws {Error} If there is an error retrieving the invitation reward amount.
 */
async function getInvitationRewardAmount() {
  errorMessage.value = null;
  loadingInvitation.value = true;
  try {
    const account = new MemoryAccount(invitationCode.value);
    const sdk = new AeSdk({
      onCompiler: new CompilerHttp("https://v7.compiler.aepps.com"),
      nodes,
      accounts: [account],
    });
    sdk.selectNode(activeNetwork.value.name);

    const affiliationTreasury = await getAffiliationTreasury(sdk);

    const invitationCodes = await affiliationTreasury
      .invitationCodes()
      .catch(() => ({}));

    const invitation = invitationCodes.get(account.address);
    if (invitation) {
      invitationSender.value = invitation[0];
      invitationAmount.value = Decimal.fromBigNumberString(invitation[1]);
      invitationClaimed.value = invitation[2];

      if (invitationClaimed.value) {
        errorMessage.value = "This invitation has already been claimed.";

        setTimeout(() => {
          onClose();
        }, 3000);
      }
    }
  } catch (error: any) {
    if (error?.message?.includes("Trying to call undefined function")) {
      errorMessage.value = "Please switch to the correct network.";
    } else {
      errorMessage.value = error?.message;
    }
  }
  loadingInvitation.value = false;
}

async function claimOrRevokeReward() {
  if (!invitationCode.value || !activeAccount.value) {
    return false;
  }
  claimingReward.value = true;
  const sdk = await getAeSdk();
  const affiliationTreasury = await getAffiliationTreasury(sdk);

  try {
    if (isRevoking.value) {
      const account = new MemoryAccount(invitationCode.value);
      await affiliationTreasury.revokeInvitationCode(account.address);
      successMessage.value = "Reward revoked successfully.";
    } else {
      await affiliationTreasury.redeemInvitationCode(
        invitationCode.value,
        activeAccount.value
      );
      successMessage.value = "Reward claimed successfully.";
    }
    invitationClaimed.value = true;
  } catch (error: any) {
    if (error?.message?.includes("INVITEE_ALREADY_REGISTERED")) {
      errorMessage.value =
        "You can't claim this reward with the current active account, try with another account.";
    } else {
      errorMessage.value = `Failed to claim reward. Reason: ${
        error?.message || "Unknown"
      }`;
    }
  }
  claimingReward.value = false;
}

function onClose() {
  localStorage.removeItem("invite_code");
  router.push({ query: { invite_code: null } });
  invitationCode.value = null;
}
</script>
