import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]




export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryName',
  props: {
  labeled: Boolean,
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createBlock(_component_v_chip, {
    class: "bcl-category-name",
    size: "small"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      (__props.labeled)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            textContent: _toDisplayString(_ctx.$t('common.token')),
            class: "ml-1"
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})