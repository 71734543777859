import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, PropType } from "vue";
import { toAe } from "@aeternity/aepp-sdk";

import { PriceDto } from "@/api/generated";
import { useCurrencies } from "@/composables";
import { Decimal } from "@/libs/decimal";

import PriceFormatter from "@/components/Common/Pricing/PriceFormatter.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PriceDataFormatter',
  props: {
  priceData: {
    type: Object as PropType<PriceDto>,
    required: true,
  },
  bignumber: Boolean,
  row: Boolean,
},
  setup(__props) {

const props = __props;

const { currentCurrencyCode, getFiat } = useCurrencies();

const aePrice = computed(() => {
  if (!props.priceData?.ae) {
    return Decimal.ZERO;
  }
  return props.bignumber
    ? Decimal.from(toAe(props.priceData.ae))
    : Decimal.from(props.priceData.ae);
});

const fiatPrice = computed(() => {
  if (!props.priceData?.ae) {
    return Decimal.ZERO;
  }
  if (!props.priceData[currentCurrencyCode.value]) {
    if (props.priceData["ae"]) {
      return getFiat(
        props.bignumber
          ? Decimal.from(toAe(props.priceData["ae"]))
          : Decimal.from(props.priceData["ae"])
      );
    }

    return Decimal.ZERO;
  }
  return props.bignumber
    ? Decimal.from(toAe(props.priceData[currentCurrencyCode.value]))
    : Decimal.from(props.priceData[currentCurrencyCode.value]);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PriceFormatter, {
    "ae-price": aePrice.value,
    "fiat-price": fiatPrice.value,
    row: __props.row
  }, null, 8, ["ae-price", "fiat-price", "row"]))
}
}

})