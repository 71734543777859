import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-uppercase text-center" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = {
  key: 0,
  class: "social-links mt-12 pt-12 border-t-sm"
}
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["href"]

import { useDisplay } from "vuetify";

import { SETTINGS } from "@/utils/constants";

import AppLogo from "@/components/Common/AppLogo.vue";

import TwitterIcon from "@/assets/logos/twitter-logo.svg";


export default /*@__PURE__*/_defineComponent({
  __name: 'CountDown',
  setup(__props) {

const { smAndDown } = useDisplay();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["count-down", {
      'd-flex align-center justify-center': !_unref(smAndDown),
      'px-4 py-8': _unref(smAndDown),
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(AppLogo, { class: "logo" }),
      _createElementVNode("h1", {
        textContent: _toDisplayString(_ctx.$t('views.countdown.pageTitle')),
        class: "text-h4 mt-6"
      }, null, 8, _hoisted_2),
      _createElementVNode("p", {
        textContent: _toDisplayString(_ctx.$t('common.comingSoon')),
        class: "text-h2 text-primary mt-4"
      }, null, 8, _hoisted_3),
      (_unref(SETTINGS).social.x)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", {
              textContent: _toDisplayString(_ctx.$t('views.countdown.follow')),
              class: "text-h6 mb-2"
            }, null, 8, _hoisted_5),
            _createElementVNode("a", {
              href: _unref(SETTINGS).social.x,
              target: "_blank"
            }, [
              _createVNode(_unref(TwitterIcon), { style: {"width":"22px"} })
            ], 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})