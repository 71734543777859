<template>
  <v-card
    v-if="token?.sale_address"
    block
    flat
    class="px-2 py-4 token-card"
    :to="{
      name: ROUTE_SALE,
      params: { contractAddress: token.sale_address },
    }"
  >
    <v-row>
      <v-col cols="2" class="py-1">
        <div :class="['rank']">
          # <b>{{ token.rank }}</b>
        </div>
      </v-col>
      <v-col cols="10" sm="7" class="py-1 pl-0">
        <div class="token-name">
          {{ token.symbol }}
        </div>
      </v-col>
      <v-col cols="3" class="py-1 pl-0 hidden-xs text-right">
        <AddressChip :address="token.sale_address" />
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="8" offset="2" class="pl-0 py-1">
        <v-row class="pricing" v-if="token.price">
          <v-col cols="3" class="pr-0 py-1">{{ $t("common.price") }}:</v-col>
          <v-col cols="9" class="py-1">
            <PriceDataFormatter
              v-if="tokenData?.price_data"
              row
              :price-data="tokenData.price_data"
            />
          </v-col>
          <v-col cols="3" class="pr-0 py-1">
            {{ $t("common.marketCapShort") }}:
          </v-col>
          <v-col cols="9" class="py-1">
            <PriceDataFormatter
              v-if="tokenData?.market_cap_data"
              row
              bignumber
              :price-data="tokenData.market_cap_data"
            />
          </v-col>
        </v-row>
        <v-skeleton-loader v-else type="chip" />
      </v-col>
      <v-col cols="2" class="px-0 py-1">
        <TokenLineChart
          :sale-address="token.address"
          chart-type="line"
          :height="50"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";

import { ROUTE_SALE } from "@/utils/constants";
import { TokenDto } from "@/api/generated";
import { useLiveTokenData } from "@/composables";

import PriceDataFormatter from "@/components/Common/Pricing/PriceDataFormatter.vue";
import TokenLineChart from "@/components/Charts/TokenLineChart.vue";
import AddressChip from "@/components/Common/AddressChip.vue";

const props = defineProps<{
  token: TokenDto;
}>();

const { tokenData } = useLiveTokenData({
  token: props.token,
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables" as *;

.token-card {
  .rank {
    font-size: 21px;
    opacity: 0.3;
  }

  .token-name {
    font-size: 36px;
    letter-spacing: 1.12px;
    font-weight: 600;
    font-style: normal;
    line-height: 100%;
    transition: all 0.2s;
  }

  .pricing {
    font-size: 13px;
  }

  &:hover {
    .rank {
      opacity: 0.8;
    }

    .token-name {
      color: rgb(var(--v-theme-primary));
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 480px) {
    .rank {
      font-size: 17px;
    }

    .token-name {
      font-size: 28px;
      color: rgb(var(--v-theme-primary));
    }
  }
}
</style>
