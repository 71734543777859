<template>
  <v-bottom-navigation
    v-if="smAndDown"
    class="bcl-bottom-navigation"
    grow
    fixed
  >
    <v-btn :to="{ name: ROUTE_HOME }" exact color="primary" class="menu-link">
      <span v-text="$t('blocks.appBottomNav.tokens')" />
    </v-btn>

    <v-btn
      :to="{ name: ROUTE_CREATE_TOKEN }"
      exact
      color="primary"
      class="menu-link"
    >
      <span v-text="$t('blocks.appBottomNav.createToken')" />
    </v-btn>

    <v-btn :to="{ name: ROUTE_INVITE }" exact color="primary" class="menu-link">
      <span v-text="$t('blocks.appBottomNav.invite')" />
    </v-btn>

    <v-btn :to="{ name: ROUTE_ABOUT }" exact color="primary" class="menu-link">
      <span v-text="$t('blocks.appBottomNav.about')" />
    </v-btn>
  </v-bottom-navigation>
</template>

<script lang="ts" setup>
import { useDisplay } from "vuetify";
import {
  ROUTE_ABOUT,
  ROUTE_CREATE_TOKEN,
  ROUTE_HOME,
  ROUTE_INVITE,
} from "@/utils/constants";

const { smAndDown } = useDisplay();
</script>

<style scoped lang="scss">
@use "@/styles/variables" as *;

.bcl-bottom-navigation {
  padding-bottom: env(safe-area-inset-top);
  height: calc(46px + env(safe-area-inset-top)) !important;
  border-top: 2px solid rgb(var(--v-theme-primary));
  background-color: $color-card-bg-transparent;
  backdrop-filter: blur(10px);

  .menu-link {
    font-size: 16px;
    text-transform: uppercase;
  }
}
</style>
