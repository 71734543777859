<template>
  <PageHeader
    v-if="!activeAccount && !invitationCode"
    class="wallet-connect-card"
  >
    <template #title>
      <i18n-t keypath="blocks.walletConnectCard.title">
        <span class="text-primary">{{ SETTINGS.wallet.name }}</span>
      </i18n-t>
    </template>

    <template #text>
      <p
        v-text="$t('blocks.walletConnectCard.desc1')"
        class="text-subtitle-1"
      />
      <p
        v-text="$t('blocks.walletConnectCard.desc2')"
        class="text-subtitle-1"
      />

      <div class="d-flex flex-wrap ga-4 mt-6">
        <WalletConnectBtn :block="smAndDown" />
        <HowItWorksDialog />
      </div>
    </template>
  </PageHeader>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";

import { SETTINGS } from "@/utils/constants";
import { useAccounts } from "@/stores/accounts";
import { useInvitation } from "@/composables";

import PageHeader from "@/components/Common/PageHeader.vue";
import WalletConnectBtn from "@/components/WalletConnect/WalletConnectBtn.vue";
import HowItWorksDialog from "@/components/Dialogs/HowItWorksDialog.vue";

const { activeAccount } = storeToRefs(useAccounts());
const { smAndDown } = useDisplay();

const { invitationCode } = useInvitation();
</script>
