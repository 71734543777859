<template>
  <CountDown v-if="SETTINGS.showCountdown" />

  <div
    v-else-if="loadingApp"
    class="d-flex align-center justify-center"
    style="height: 100vh; width: 100vw"
  >
    <div class="text-center">
      <v-progress-circular indeterminate color="primary" size="80" />
    </div>
  </div>

  <v-app v-else class="app" :theme="darkMode ? 'dark' : 'light'">
    <AppHeader />

    <v-main class="py-0 px-3 px-sm-4 app-main">
      <CollectInvitationLinkCard />
      <router-view />
    </v-main>

    <BottomNavigation />
  </v-app>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";

import AppHeader from "@/components/Header/AppHeader.vue";
import BottomNavigation from "@/components/BottomNavigation.vue";
import CollectInvitationLinkCard from "@/components/Invitation/CollectInvitationLinkCard.vue";
import CountDown from "@/components/CountDown.vue";

import { SETTINGS } from "@/utils/constants";
import WebSocketClient from "@/libs/WebSocketClient";
import { useAppStore } from "@/stores/app";
import { useNetworkStore } from "@/stores/network";
import {
  useAeppSdk,
  useCurrencies,
  useTokenSaleFactory,
  useWalletConnect,
} from "@/composables";
import { OpenAPI } from "@/api/generated";

const { darkMode } = storeToRefs(useAppStore());
const { initSdk, staticAeSdk, getSdk } = useAeppSdk();
const { activeNetwork } = storeToRefs(useNetworkStore());
const { checkWalletConnection } = useWalletConnect();
const { loadAeternityData, loadCurrencyRates } = useCurrencies();
const { initTokenSaleFactory } = useTokenSaleFactory();

const loadingApp = ref(true);

watch(
  activeNetwork,
  async (network, prevNetwork) => {
    if (SETTINGS.showCountdown || !network) {
      return;
    }
    OpenAPI.BASE = `${network.apiUrl}`;
    try {
      await Promise.all([loadAeternityData(), loadCurrencyRates()]);
    } catch (e) {
      console.error("Error loading data", e);
    }
    try {
      await initSdk();
      const sdk = await getSdk();
      sdk.selectNode(network.name);
      staticAeSdk.selectNode(network.name);
      checkWalletConnection();
      initTokenSaleFactory();
    } catch (e) {
      //
    }
    loadingApp.value = false;
    if (network?.websocketUrl !== prevNetwork?.websocketUrl) {
      WebSocketClient.disconnect();
      WebSocketClient.connect(network.websocketUrl);
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
@use "@/styles/variables" as *;

.app {
  .app-main {
    width: 100%;
    margin-bottom: 56px;
    margin-top: 80px;
  }
}
</style>
