import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]

import { PropType } from "vue";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";

import { useWalletConnect } from "@/composables";
import { useAccounts } from "@/stores/accounts";

import SuperheroWalletIcon from "@/assets/logos/sh-wallet.svg";


export default /*@__PURE__*/_defineComponent({
  __name: 'WalletConnectBtn',
  props: {
  label: { type: String, default: null },
  size: { type: String as PropType<"large" | undefined>, default: undefined },
  outlined: Boolean,
  hideLabelOnSm: Boolean,
  block: Boolean,
},
  setup(__props) {

const { smAndDown } = useDisplay();
const { connectWallet, scanningForWallets } = useWalletConnect();
const { activeAccount } = storeToRefs(useAccounts());



return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (!_unref(activeAccount))
    ? (_openBlock(), _createBlock(_component_v_btn, {
        key: 0,
        class: "wallet-connect-btn",
        color: "primary",
        "data-cy": "connect-wallet",
        variant: __props.outlined ? 'outlined' : 'elevated',
        size: __props.size,
        block: __props.block,
        loading: _unref(scanningForWallets),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(connectWallet)()))
      }, {
        default: _withCtx(() => [
          (!(_unref(smAndDown) && !__props.hideLabelOnSm))
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                textContent: _toDisplayString(__props.label ?? _ctx.$t('common.connectWallet')),
                class: "d-inline-block mr-2"
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          _createVNode(_unref(SuperheroWalletIcon), { class: "wallet-icon" })
        ]),
        _: 1
      }, 8, ["variant", "size", "block", "loading"]))
    : _createCommentVNode("", true)
}
}

})