import type { IAppSettings } from "@/utils/types";

const settings: IAppSettings = {
  showCountdown: process.env.VUE_APP_SHOW_COUNTDOWN === "true",
  app: {
    name: process.env.VUE_APP_NAME || "",
    logo: {
      width: "187px",
      height: "30px",
    },
  },
  wallet: {
    name: "Superhero Wallet",
    url: "https://wallet.superhero.com",
  },
  networks: {
    ae_mainnet: {
      name: "Mainnet",
      networkId: "ae_mainnet",
      apiUrl: process.env.VUE_APP_MAINNET_API || "",
      websocketUrl: process.env.VUE_APP_MAINNET_WS || "",
      url: "https://mainnet.aeternity.io",
      middlewareUrl: "https://mainnet.aeternity.io/mdw",
      explorerUrl: "https://aescan.io",
      compilerUrl: "https://v7.compiler.aepps.com",
      disabled: true,
    },
    ae_uat: {
      name: "Testnet",
      networkId: "ae_uat",
      apiUrl: process.env.VUE_APP_TESTNET_API || "",
      websocketUrl: process.env.VUE_APP_TESTNET_WS || "",
      url: "https://testnet.aeternity.io",
      middlewareUrl: "https://testnet.aeternity.io/mdw",
      explorerUrl: "https://testnet.aescan.io",
      compilerUrl: "https://v7.compiler.aepps.com",
    },
  },
  social: {
    x: undefined,
  },
  vuetify: {
    theme: {
      defaultTheme: "dark",
      themes: {
        dark: {
          dark: true,
          colors: {
            primary: "#F4C10C",
            surface: "#1A1A1A",
          },
        },
      },
    },
  },
};

export default settings;
