import { computed } from "vue";
import { storeToRefs } from "pinia";
import { toAe } from "@aeternity/aepp-sdk";

import type { CurrencyCode, ICurrency } from "@/utils/types";
import {
  AETERNITY_COIN_ID,
  AETERNITY_TOKEN_BASE_DATA,
  CURRENCIES,
} from "@/utils/constants";
import { PriceDto } from "@/api/generated";
import { CoinGecko } from "@/libs/CoinGecko";
import { Decimal } from "@/libs/decimal";
import { useCurrenciesStore } from "@/stores/currencies";

export function useCurrencies() {
  const { aeternityData, currencyRates, currentCurrencyCode } = storeToRefs(
    useCurrenciesStore()
  );

  const currentCurrencyRate = computed(
    (): number => currencyRates.value[currentCurrencyCode.value] || 0
  );
  const currentCurrencyInfo = computed(
    (): ICurrency =>
      CURRENCIES.find(({ code }) => code === currentCurrencyCode.value)!
  );

  async function loadAeternityData() {
    try {
      const aeMarketData = await CoinGecko.fetchCoinMarketData(
        AETERNITY_COIN_ID,
        currentCurrencyCode.value
      );

      aeternityData.value = {
        ...(aeMarketData || ({} as any)),
        ...AETERNITY_TOKEN_BASE_DATA,
        convertedBalance: Decimal.ZERO,
      };
    } catch (e) {
      aeternityData.value = undefined;
    }
  }

  function setCurrentCurrency(currency: CurrencyCode) {
    currentCurrencyCode.value = currency;
    loadAeternityData();
  }

  async function loadCurrencyRates() {
    const fetchedCurrencyRates = await CoinGecko.fetchCoinCurrencyRates(
      AETERNITY_COIN_ID
    );

    if (fetchedCurrencyRates) {
      currencyRates.value = fetchedCurrencyRates;
    }
  }

  /**
   * @returns value formatted as a currency according to the user's browser settings
   *   eg.: "23 789,98 £", "$ 25.269,00"
   */
  function formatCurrency(value: Decimal): string {
    return `${currentCurrencyInfo.value.symbol} ${value.moneyPrettify()}`;
  }

  /**
   * @param value Aeternity coin amount
   * @returns Aeternity coin converted to fiat
   */
  function getFiat(value: Decimal): Decimal {
    return value?.mul(currentCurrencyRate.value);
  }

  function getFormattedActiveCurrencyFiat(value: PriceDto) {
    if (!value) {
      return null;
    }
    return formatCurrency(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Decimal.from(value[currentCurrencyCode.value as any] || 0)
    );
  }

  function getFormattedBigNumberFiat(value: PriceDto) {
    if (!value) {
      return null;
    }
    return formatCurrency(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Decimal.from(toAe(value[currentCurrencyCode.value as any]) || 0)
    );
  }

  /**
   * @param value Aeternity coin amount
   * @returns Aeternity coin converted to fiat and formatted as a currency
   *   according to the user's browser settings
   */
  function getFormattedFiat(value: Decimal) {
    return formatCurrency(getFiat(value));
  }

  /**
   * Does the same as `getFormattedFiat` but avoids displaying small fractions
   * by rounding them to 0.01.
   * @param value Aeternity coin amount
   */
  function getFormattedAndRoundedFiat(value: Decimal): string {
    if (!currentCurrencyRate.value || value === Decimal.ZERO) {
      return formatCurrency(Decimal.ZERO);
    }
    const converted = getFiat(value);

    return converted.lt(Decimal.from(0.01))
      ? `<${formatCurrency(converted)}`
      : formatCurrency(converted);

    // return converted < 0.01
    //   ? `<${formatCurrency(0.01 as any)}`
    //   : formatCurrency(converted);
  }

  return {
    aeternityData,
    currencyRates,
    currentCurrencyCode,
    currentCurrencyInfo,

    loadAeternityData,
    setCurrentCurrency,
    loadCurrencyRates,

    formatCurrency,
    getFormattedFiat,
    getFormattedAndRoundedFiat,

    //
    getFormattedActiveCurrencyFiat,
    getFormattedBigNumberFiat,
    getFiat,
  };
}
