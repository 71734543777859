import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"




export default /*@__PURE__*/_defineComponent({
  __name: 'AddressAvatar',
  props: {
    address: {},
    size: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!

  return (_openBlock(), _createBlock(_component_v_avatar, {
    size: _ctx.size,
    start: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_img, {
        src: `https://avatars.z52da5wt.xyz/${_ctx.address}`
      }, null, 8, ["src"])
    ]),
    _: 1
  }, 8, ["size"]))
}
}

})