<template>
  <v-chip
    v-if="address"
    v-bind="$attrs"
    class="bcl-address-chip"
    :class="{ 'pa-4': large }"
    :color="activeAccount === address ? 'primary' : undefined"
    size="small"
    @click.prevent="onChipClick()"
  >
    <AddressAvatar v-if="!hideAvatar" :address="address" />
    <div :class="{ 'text-large': large }">
      {{ formatAddress(address) }}
    </div>
    <v-avatar v-if="link && !isAccountAddress" end>
      <v-icon>mdi-open-in-new</v-icon>
    </v-avatar>
    <v-avatar v-if="copyable" end>
      <v-icon>mdi-content-copy</v-icon>
    </v-avatar>
    <div v-if="textCopied" class="bg-green text-copied">
      {{ $t("common.copied") }}
    </div>
  </v-chip>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { computed, defineProps, ref } from "vue";
import { useRouter } from "vue-router";
import { Encoding } from "@aeternity/aepp-sdk";

import { useAeScan } from "@/composables";
import { useAccounts } from "@/stores/accounts";
import { formatAddress } from "@/utils/address";
import { validateHash } from "@/utils/common";

import AddressAvatar from "./AddressAvatar.vue";

const props = defineProps<{
  address: string;
  hideAvatar?: boolean;
  link?: boolean;
  copyable?: boolean;
  prefix?: string;
  large?: boolean;
}>();

const router = useRouter();
const { prepareUrlForHash } = useAeScan();
const { activeAccount } = storeToRefs(useAccounts());

const textCopied = ref(false);

const isAccountAddress = computed(() => {
  const { valid, prefix } = validateHash(props.address);
  return valid && prefix === Encoding.AccountAddress;
});

function onChipClick() {
  if (props.copyable) {
    navigator.clipboard.writeText(props.address);
    textCopied.value = true;

    setTimeout(() => {
      textCopied.value = false;
    }, 1000);
  } else if (isAccountAddress.value) {
    return router.push(`/accounts/${props.address}`);
  } else if (props.link) {
    window.open(prepareUrlForHash(props.address, props.prefix), "_blank");
  }
}
</script>

<style lang="scss">
@use "@/styles/variables" as *;

.bcl-address-chip {
  font-size: 14px;
  font-weight: 600;

  .text-large {
    font-size: 18px;
  }

  .text-copied {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 700;
  }
}
</style>
