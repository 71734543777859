import { Decimal } from "@/libs/decimal";
import { defer } from "lodash-es";
import { watch, type WatchSource } from "vue";
import {
  AE_AENS_DOMAIN,
  AE_HASH_PREFIXES_ALLOWED,
  HASH_REGEX,
} from "./constants";

/**
 * Watch for the getter to be truthy with the use of the compositionApi.
 */
export function watchUntilTruthy<T>(
  getter: WatchSource<T>
): Promise<NonNullable<T>> {
  return new Promise((resolve) => {
    const unwatch = watch(
      getter,
      (value) => {
        if (value) {
          resolve(value as NonNullable<T>);
          defer(() => unwatch());
        }
      },
      { immediate: true }
    );
  });
}

export async function fetchJson<T = any>(
  url: string,
  options?: RequestInit
): Promise<T | null> {
  const response = await fetch(url, options);
  if (response.status === 204) {
    return null;
  }
  return response.json();
}

export const panic = <T>(e: unknown): T => {
  throw e;
};

export function validateHash(fullHash?: string) {
  type HashPrefix = (typeof AE_HASH_PREFIXES_ALLOWED)[number];
  const isName = !!fullHash?.endsWith(AE_AENS_DOMAIN);
  let valid = false;
  let prefix: HashPrefix | null = null;
  let hash = null;

  if (fullHash) {
    [prefix, hash] = fullHash.split("_") as [HashPrefix, string];
    valid =
      (AE_HASH_PREFIXES_ALLOWED.includes(prefix) && HASH_REGEX.test(hash)) ||
      isName;
  }

  return {
    valid,
    isName,
    prefix,
    hash,
  };
}

/**
 * Formats a fractional price string into an object containing the number, zeros count,
 * significant digits, and a formatted value string.
 *
 * @param Decimal - The input price.
 * @returns An object containing:
 * - `number`: A string representation of the number with leading zeros.
 * - `zerosCount`: The count of leading zeros in the fractional part.
 * - `significantDigits`: The first four significant digits in the fractional part.
 * - `value`: A formatted string representing the fractional part with leading zeros.
 */
export function formatFractionalPrice(input: Decimal): {
  number: string;
  zerosCount?: number;
  significantDigits?: string;
  value?: string;
} {
  const inputStr = input.prettify(18);
  if (input.isZero) {
    return {
      number: "0.00",
    };
  }
  if (input.gte(Decimal.from("1"))) {
    return {
      number: input.prettify(2),
    };
  }
  if (input.gte(Decimal.from("0.001"))) {
    return {
      number: input.prettify(6),
    };
  }
  const [, fractionalPart = ""] = inputStr.split(".");

  // Handle fractional parts
  const nonZeroIndex = fractionalPart.search(/[1-9]/);
  // if contain -e
  let zerosCount = 0;
  if (inputStr.includes("e")) {
    const [, exponent] = inputStr.split("e-");
    const exp = parseInt(exponent, 10);

    zerosCount = exp - 2;
  } else {
    zerosCount = nonZeroIndex - 1;
  }

  const value = `0.0 (${zerosCount}) ${fractionalPart.substr(nonZeroIndex, 4)}`;
  return {
    number: "0.0",
    zerosCount,
    significantDigits: fractionalPart.substr(nonZeroIndex, 4),
    value,
  };
}
