<template>
  <PriceFormatter :ae-price="aePrice" :fiat-price="fiatPrice" v-bind="$attrs" />
</template>

<script lang="ts" setup>
import { computed, defineProps, PropType } from "vue";
import { useCurrencies } from "@/composables";
import { Decimal } from "@/libs/decimal";
import PriceFormatter from "@/components/Common/Pricing/PriceFormatter.vue";

const props = defineProps({
  aePrice: {
    type: Object as PropType<Decimal>,
    required: true,
  },
});

const { getFiat } = useCurrencies();

const fiatPrice = computed(() => {
  return getFiat(props.aePrice);
});
</script>
