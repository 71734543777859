<template>
  <v-btn
    v-if="!activeAccount"
    class="wallet-connect-btn"
    color="primary"
    data-cy="connect-wallet"
    :variant="outlined ? 'outlined' : 'elevated'"
    :size="size"
    :block="block"
    :loading="scanningForWallets"
    @click="connectWallet()"
  >
    <span
      v-if="!(smAndDown && !hideLabelOnSm)"
      v-text="label ?? $t('common.connectWallet')"
      class="d-inline-block mr-2"
    />

    <SuperheroWalletIcon class="wallet-icon" />
  </v-btn>
</template>

<script lang="ts" setup>
import { defineProps, PropType } from "vue";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";

import { useWalletConnect } from "@/composables";
import { useAccounts } from "@/stores/accounts";

import SuperheroWalletIcon from "@/assets/logos/sh-wallet.svg";

const { smAndDown } = useDisplay();
const { connectWallet, scanningForWallets } = useWalletConnect();
const { activeAccount } = storeToRefs(useAccounts());

defineProps({
  label: { type: String, default: null },
  size: { type: String as PropType<"large" | undefined>, default: undefined },
  outlined: Boolean,
  hideLabelOnSm: Boolean,
  block: Boolean,
});
</script>

<style lang="scss" scoped>
.wallet-connect-btn {
  .wallet-icon {
    height: 18px;
  }
}
</style>
