import { Encoded } from "@aeternity/aepp-sdk";
import { useDao } from "./useDao";
import { useAeppSdk } from "./aeppSdk";
import {
  initDAOVote,
  toTokenDecimals,
  Vote,
  VOTE_STATE_LABEL,
  VoteState,
} from "token-gating-sdk";
import { computed, ref, shallowRef, watch } from "vue";
import { storeToRefs } from "pinia";
import { useAccounts } from "@/stores/accounts";

export interface UseDaoVoteProps {
  tokenSaleAddress: Encoded.ContractAddress;
  voteAddress: Encoded.ContractAddress;
  voteId: bigint;
}

export function useDaoVote({
  tokenSaleAddress,
  voteAddress,
  voteId,
}: UseDaoVoteProps) {
  const vote = shallowRef<Vote>();
  const voteState = ref<VoteState>();
  const voteStateLabel = ref<VOTE_STATE_LABEL>();
  const actionLoading = ref<boolean>(false);

  const { getAeSdk, aeSdk } = useAeppSdk();
  const { activeAccount } = storeToRefs(useAccounts());

  const dao = useDao({
    tokenSaleAddress,
  });

  watch(
    () => tokenSaleAddress,
    () => {
      init();
    },
    {
      immediate: true,
    }
  );

  const canVote = computed(
    () =>
      activeAccount.value &&
      voteState.value &&
      voteStateLabel.value &&
      aeSdk &&
      vote.value?.canVote(
        voteStateLabel.value,
        voteState.value,
        activeAccount.value as any
      )
  );

  const canRevokeVote = computed(
    () =>
      voteState.value &&
      voteStateLabel.value &&
      activeAccount.value &&
      aeSdk &&
      vote.value?.canRevokeVote(
        voteStateLabel.value,
        voteState.value,
        activeAccount.value as any
      )
  );

  const canWithdraw = computed(
    () =>
      voteState.value &&
      voteStateLabel.value &&
      aeSdk &&
      vote.value?.canWithdraw(
        voteStateLabel.value,
        voteState.value,
        activeAccount.value as any
      )
  );

  const canApply = computed(
    () => voteStateLabel.value && vote.value?.canApply(voteStateLabel.value)
  );

  const voteYesPercentage = computed(
    () => voteState.value && vote.value?.voteYesPercentage(voteState.value)
  );

  const voteStakeYesPercentage = computed(() =>
    voteState.value && dao.tokenSupply.value
      ? vote.value?.voteStakeYesPercentage(
          voteState.value,
          dao.tokenSupply.value
        )
      : 1
  );

  const userVoteOrLockedInfo = computed(() => {
    if (
      voteState.value &&
      dao.tokenMetaInfo.value &&
      aeSdk &&
      vote.value?.accountVoted(voteState.value, activeAccount.value as any)
    ) {
      const accountVotedBalance = vote.value?.accountVotedBalance(
        voteState.value,
        activeAccount.value as any
      );

      const accountVotedBalanceTokenDecimals =
        accountVotedBalance !== undefined &&
        toTokenDecimals(
          accountVotedBalance,
          dao.tokenMetaInfo.value.decimals,
          0n
        );

      const accountHasLockedBalance = vote.value?.accountHasLockedBalance(
        voteState.value,
        activeAccount.value as any
      );

      const accountVotedAgreement = vote.value?.accountVotedAgreement(
        voteState.value,
        activeAccount.value as any
      );

      return `You ${
        accountHasLockedBalance ? "Locked" : "Voted"
      } ${accountVotedBalanceTokenDecimals} ${
        dao.tokenMetaInfo.value.symbol
      } in ${accountVotedAgreement ? "Agreement" : "Disagreement"}`;
    } else return "";
  });

  async function refreshVoteState() {
    voteState.value = await vote.value?.state();
    console.log("###############");
    console.log("refreshVoteState->::", voteState.value);
    console.log("dao.state?.value->::", dao.state?.value);
    console.log("dao.tokenSupply?.value->::", dao.tokenSupply?.value);
    voteStateLabel.value =
      voteState.value &&
      dao.state?.value &&
      dao.tokenSupply?.value !== undefined
        ? await vote.value?.voteStateLabel(
            voteState.value,
            dao.state.value,
            dao.tokenSupply?.value
          )
        : undefined;
    console.log("voteStateLabel.value->::", voteStateLabel.value);
    console.log("###############");
  }

  async function init() {
    const aeSdk = await getAeSdk();
    vote.value = await initDAOVote(aeSdk, voteAddress, voteId);
    await refreshVoteState();
    console.log("############# VOTE YES PERCENTAGE ####");
    console.log(voteYesPercentage.value);
  }

  async function runTestCheck() {
    console.log("run test check");
    console.log("voteStateLabel.value", voteStateLabel.value);
    console.log("voteState.value", voteState.value);
    console.log("dao.tokenSupply?.value", dao.tokenSupply?.value);
    if (!voteState.value) {
      return;
    }

    if (dao.tokenSupply?.value) {
      const vsl = await vote.value?.voteStateLabel(
        voteState.value,
        dao.state.value,
        dao.tokenSupply?.value
      );
      console.log("vsl", vsl);
    }

    //
    if (voteStateLabel.value && voteState.value) {
      const canV = vote.value?.canVote(
        voteStateLabel.value,
        voteState.value,
        activeAccount.value as any
      );
      console.log("can vote", canV);
    }
  }

  async function applyAction(action: () => unknown | Promise<unknown>) {
    actionLoading.value = true;
    try {
      await action();
    } catch (e) {
      console.error(e);
    }
    await refreshVoteState();
    await dao.init();
    actionLoading.value = false;
  }

  function revokeVote() {
    void applyAction(() => vote.value?.revokeVote());
  }

  function withdraw() {
    void applyAction(() => vote.value?.withdraw());
  }

  async function voteOption(option: boolean) {
    void applyAction(async () =>
      vote.value?.vote(
        option,
        dao.userTokenBalance.value!,
        dao.tokenInstanceRef.value!
      )
    );
  }

  return {
    vote,
    voteState,

    canVote,
    canRevokeVote,
    canWithdraw,
    canApply,
    userVoteOrLockedInfo,
    actionLoading,

    voteYesPercentage,
    voteStakeYesPercentage,
    runTestCheck,

    // methods
    voteOption,
    revokeVote,
    withdraw,
  };
}
