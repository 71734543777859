import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 2 }
const _hoisted_2 = {
  key: 4,
  class: "pl-1"
}

import { PropType, ref, watch } from "vue";

import { useCurrencies } from "@/composables/useCurrencies";
import { Decimal } from "@/libs/decimal";
import { formatFractionalPrice } from "@/utils/common";
import { AETERNITY_SYMBOL } from "@/utils/constants";

import FractionFormatter from "./FractionFormatter.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PriceFormatter',
  props: {
  aePrice: {
    type: Object as PropType<Decimal>,
    required: true,
  },
  fiatPrice: {
    type: Object as PropType<Decimal>,
    required: true,
  },
  symbol: {
    type: String,
    default: AETERNITY_SYMBOL,
  },
  watchPrice: {
    type: Boolean,
    default: true,
  },
  priceLoading: Boolean,
  hideFiatPrice: Boolean,
  hideSymbol: Boolean,
  row: Boolean,
},
  setup(__props) {

const props = __props;

const { currentCurrencyInfo } = useCurrencies();

const priceJustIncreased = ref(false);
const priceJustDecreased = ref(false);

watch(
  () => props.aePrice,
  (value, prev) => {
    if (!value || !prev || !props.watchPrice) return;
    if (value.gte(prev)) {
      priceJustIncreased.value = true;
      setTimeout(() => {
        priceJustIncreased.value = false;
      }, 500);
    } else {
      priceJustDecreased.value = true;
      setTimeout(() => {
        priceJustDecreased.value = false;
      }, 500);
    }
  }
);

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'price-formatter',
      {
        'text-red': priceJustDecreased.value,
        'text-green': priceJustIncreased.value,
        'd-flex align-center ga-1': __props.row,
      },
    ])
  }, [
    _createElementVNode("div", _mergeProps({ class: "price d-flex align-center" }, _ctx.$attrs), [
      (priceJustIncreased.value)
        ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("mdi-trending-up")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      (priceJustDecreased.value)
        ? (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("mdi-trending-down")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      false
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(__props.aePrice?.prettify(6)) + "//", 1))
        : _createCommentVNode("", true),
      _createVNode(FractionFormatter, {
        "fractional-price": _unref(formatFractionalPrice)(__props.aePrice)
      }, null, 8, ["fractional-price"]),
      (__props.priceLoading)
        ? (_openBlock(), _createBlock(_component_v_progress_circular, {
            key: 3,
            indeterminate: "",
            size: "15"
          }))
        : (!__props.hideSymbol)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.symbol), 1))
          : _createCommentVNode("", true)
    ], 16),
    (!__props.hideFiatPrice)
      ? (_openBlock(), _createElementBlock("div", _mergeProps({
          key: 0,
          class: ['fiat d-flex align-center ga-1', { 'justify-end': __props.row }]
        }, _ctx.$attrs), [
          _createElementVNode("div", null, _toDisplayString(_unref(currentCurrencyInfo).symbol), 1),
          _createVNode(FractionFormatter, {
            "fractional-price": _unref(formatFractionalPrice)(__props.fiatPrice)
          }, null, 8, ["fractional-price"])
        ], 16))
      : _createCommentVNode("", true)
  ], 2))
}
}

})