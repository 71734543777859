<template>
  <WalletConnectCard />

  <div class="home-view container" ref="scrollComponent">
    <LatestTransactionsCarousel />

    <v-row class="mt-4" align="center">
      <v-col cols="12" :md="activeAccount ? 8 : 12" lg="2" class="py-1">
        <div class="pb-2 d-flex ga-2 align-center token-list-header">
          <h2 v-text="$t('common.tokenList')" class="text-h4" />
        </div>
      </v-col>
      <v-col v-if="activeAccount" cols="12" md="4" lg="2" class="py-1">
        <v-switch
          v-model="filterForCurrentAccount"
          :label="$t('views.home.showOwnedOnly')"
          color="primary"
          hide-details
        />
      </v-col>
      <v-spacer v-else />
      <v-col cols="12" md="3" lg="2" class="py-1">
        <v-menu>
          <template #activator="{ props }">
            <v-btn variant="outlined" v-bind="props" block height="42px">
              {{ $t("common.categories") }}: {{ selectedCategory?.title }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in categoryOptions"
              :key="index"
              :disabled="item.disabled"
              @click="updateQueryFilters({ category: item.value })"
            >
              <v-list-item-title
                :class="{
                  'text-primary': queryFilters.category == item.value,
                }"
              >
                {{ item.title }}
                <small class="pl-2" v-if="item.disabled">
                  ({{ $t("common.comingSoon") }})
                </small>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" lg="2" class="py-1">
        <v-menu>
          <template #activator="{ props }">
            <v-btn variant="outlined" v-bind="props" block height="42px">
              {{ $t("common.sort") }}: {{ activeSortOption?.title }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in sortOptions"
              :key="index"
              @click="
                updateQueryFilters({
                  sort: item.value,
                })
              "
            >
              <v-list-item-title
                :class="{
                  'text-primary': queryFilters.sort == item.value,
                }"
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" lg="2" class="py-1">
        <v-menu>
          <template #activator="{ props }">
            <v-btn variant="outlined" v-bind="props" block height="42px">
              {{ $t("common.order") }}: {{ queryFilters.dir }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in sortDirectionOptions"
              :key="index"
              @click="updateQueryFilters({ dir: item.value })"
            >
              <v-list-item-title
                :class="{
                  'text-primary': queryFilters.dir == item.value,
                }"
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" lg="2" class="py-1">
        <v-text-field
          v-model="search"
          :label="$t('views.home.searchLabel')"
          variant="outlined"
          append-inner-icon="mdi-magnify"
          density="compact"
          hide-details
          single-line
        />
      </v-col>
    </v-row>

    <v-row class="position-relative">
      <div v-if="isFetching" class="loading-layer d-flex justify-center">
        <v-progress-circular indeterminate color="primary" size="80" />
      </div>

      <v-alert
        v-if="
          (!data?.pages?.length || !data?.pages[0].items.length) && !isFetching
        "
        v-text="$t('views.home.noTokenSales')"
        type="info"
        outlined
        class="ma-3"
      />
      <v-col v-if="!smAndDown" cols="12" md="12" class="pb-0 pt-8">
        <v-row class="table-title">
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div class="d-flex">
              <div style="width: 87px">
                <AppTooltip>
                  <template #activator>
                    {{ $t("common.rank") }}
                  </template>
                  Ranking is based on the market cap of the token.
                </AppTooltip>
              </div>
              <div v-text="$t('common.tokenName')" />
            </div>
          </v-col>
          <v-col cols="1" md="1" lg="1" class="text-right">
            <AppTooltip end>
              <template #activator>
                {{ $t("common.category") }}
              </template>
              Category of the token.
            </AppTooltip>
          </v-col>
          <v-col cols="1" md="2" lg="1" class="text-right">
            {{ $t("common.price") }}
          </v-col>
          <v-col cols="1" md="2" lg="1" class="text-right">
            {{ $t("common.marketCap") }}
          </v-col>
          <v-col
            v-if="!mdAndDown"
            cols="3"
            md="2"
            lg="2"
            xl="1"
            class="px-1 text-center"
          >
            {{ $t("common.contractAddress") }}
          </v-col>
          <v-col cols="1" class="px-1 text-center">
            {{ $t("common.performance") }}
          </v-col>
        </v-row>
      </v-col>
      <template v-if="data?.pages">
        <template v-for="page in data.pages">
          <template v-if="page?.items">
            <v-col
              v-for="token in page.items"
              :key="token.sale_address"
              cols="12"
              md="12"
              xl="12"
              class="py-1"
            >
              <MobileTokenSaleCard v-if="smAndDown" :token="token" />
              <TokenSaleCard
                v-else
                :token="token"
                :use-category-rank="queryFilters.category !== 'all'"
              />
            </v-col>
          </template>
        </template>
      </template>
    </v-row>

    <div class="text-center">
      <v-btn
        v-if="hasNextPage"
        v-text="$t('common.loadMore')"
        @click="fetchNextPage"
        variant="outlined"
        rounded
        size="large"
        :loading="isFetching"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { useInfiniteQuery } from "@tanstack/vue-query";
import { useI18n } from "vue-i18n";

import type { TokenCategory } from "@/utils/types";
import { TokensService } from "@/api/generated";
import WebSocketClient from "@/libs/WebSocketClient";
import { useNetwork } from "@/composables";
import { useAccounts } from "@/stores/accounts";

import MobileTokenSaleCard from "@/components/Tokens/TokenSaleCard/MobileTokenSaleCard.vue";
import TokenSaleCard from "@/components/Tokens/TokenSaleCard/TokenSaleCard.vue";
import WalletConnectCard from "@/components/WalletConnect/WalletConnectCard.vue";
import LatestTransactionsCarousel from "@/components/LatestTransactionsCarousel.vue";
import AppTooltip from "@/components/Common/AppTooltip.vue";

const { smAndDown, mdAndDown } = useDisplay();
const { activeNetworkId } = useNetwork();
const { t } = useI18n();
const { activeAccount } = storeToRefs(useAccounts());
const route = useRoute();
const router = useRouter();

const queryFilters = computed(() => ({
  sort: route.query.sort ?? "market_cap",
  dir: route.query.dir ?? "DESC",
  category: route.query.category ?? "all",
}));

function updateQueryFilters(query: Record<string, string>) {
  router.push({
    query: {
      ...route.query,
      ...query,
    },
  });
}

// Filters
const filterForCurrentAccount = ref(false);
const search = ref<string>();
const sortOptions = [
  {
    title: t("common.name"),
    value: "name",
  },
  {
    title: t("common.marketCap"),
    value: "market_cap",
  },
  {
    title: t("common.rank"),
    value: "rank",
  },
  {
    title: t("common.creationDate"),
    value: "created_at",
  },
];

type TokenCategoryOption = TokenCategory | "all";
const categoryOptions: {
  title: string;
  value: TokenCategoryOption;
  disabled?: boolean;
}[] = [
  {
    title: t("common.all"),
    value: "all",
  },
  {
    title: t("categories.words.title"),
    value: "word",
  },
  {
    title: t("categories.numbers.title"),
    value: "number",
  },
  // {
  //   title: t("categories.词.title"),
  //   value: "词",
  //   disabled: true,
  // },
  // {
  //   title: t("categories.ideas.title"),
  //   value: "idea",
  //   disabled: true,
  // },
  // {
  //   title: t("categories.images.title"),
  //   value: "image",
  //   disabled: true,
  // },
];
const selectedCategory = computed(() =>
  categoryOptions.find((option) => option.value === queryFilters.value.category)
);
const activeSortOption = computed(() =>
  sortOptions.find((option) => option.value === queryFilters.value.sort)
);
const sortDirectionOptions = [
  {
    title: t("common.ascending"),
    value: "ASC",
  },
  {
    title: t("common.descending"),
    value: "DESC",
  },
];
const scrollComponent = ref<HTMLElement>();
const newTokenListenerSubscription = ref();
let refetchTimeout: NodeJS.Timeout;

const mappedSortMode = computed(() => {
  if (queryFilters.value.category === "all") {
    return queryFilters.value.sort;
  }
  if (queryFilters.value.sort !== "rank") {
    return queryFilters.value.sort;
  }
  return "category_rank";
});

const { data, isFetching, fetchNextPage, hasNextPage, refetch } =
  useInfiniteQuery({
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      TokensService.listAll({
        orderBy: mappedSortMode.value as any,
        orderDirection: queryFilters.value.dir as any,
        category: queryFilters.value.category as any,
        search: search.value,
        ownerAddress: filterForCurrentAccount.value
          ? (activeAccount.value as string)
          : undefined,
        limit: 50,
        page: pageParam,
      }),
    getNextPageParam: (lastPage: any, allPages, lastPageParam) => {
      if (lastPage?.meta?.currentPage === lastPage?.meta?.totalPages) {
        return undefined;
      }

      return lastPageParam + 1;
    },
    queryKey: [
      "TokensService.listAll",
      queryFilters,
      mappedSortMode,
      search,
      activeNetworkId,
      filterForCurrentAccount,
      activeAccount,
    ],
  });

const handleScroll = () => {
  if (
    (hasNextPage.value &&
      scrollComponent.value?.getBoundingClientRect()?.bottom) ||
    0 < window.innerHeight
  ) {
    fetchNextPage();
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  newTokenListenerSubscription.value = WebSocketClient.subscribeToNewTokenSales(
    (token: any) => {
      console.log("New token sale::", token);
      clearTimeout(refetchTimeout);
      refetchTimeout = setTimeout(() => {
        refetch();
      }, 4000);
    }
  );
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  newTokenListenerSubscription.value?.();
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables" as *;

.home-view {
  .table-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    letter-spacing: 0.42px;
    opacity: 0.8;
    margin-bottom: -4px;
  }

  .loading-layer {
    position: absolute;
    z-index: 1;
    inset: 0;
    padding-top: 100px;
    background: rgba(var(--v-theme-background), 0.5);
  }
}
</style>
