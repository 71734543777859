import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container d-flex align-center ga-1 ga-lg-2" }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "d-flex align-center ga-1 ga-sm-2" }

import { useDisplay } from "vuetify";

import {
  ROUTE_ABOUT,
  ROUTE_CREATE_TOKEN,
  ROUTE_HOME,
  ROUTE_INVITE,
} from "@/utils/constants";

import ActiveAccountBtn from "@/components/Account/ActiveAccountBtn.vue";
import WalletConnectBtn from "@/components/WalletConnect/WalletConnectBtn.vue";
import ToggleDarkMode from "@/components/Header/ToggleDarkMode.vue";
import NetworkSelector from "@/components/Header/NetworkSelector.vue";
import CurrencySelector from "@/components/Header/CurrencySelector.vue";
import AppLogo from "@/components/Common/AppLogo.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  setup(__props) {

const { smAndDown } = useDisplay();

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    app: "",
    fixed: "",
    location: "top",
    class: "bcl-app-header px-3 px-sm-4"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(AppLogo, {
            class: "logo-btn mr-md-3 mr-lg-6",
            linked: ""
          }),
          (!_unref(smAndDown))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_v_btn, {
                  class: "menu-link",
                  color: "primary",
                  to: { name: _unref(ROUTE_HOME) },
                  exact: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("common.tokenList")), 1)
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createVNode(_component_v_btn, {
                  class: "menu-link",
                  color: "primary",
                  to: { name: _unref(ROUTE_CREATE_TOKEN) }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("blocks.appHeader.createToken")), 1)
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createVNode(_component_v_btn, {
                  class: "menu-link",
                  color: "primary",
                  to: { name: _unref(ROUTE_INVITE) },
                  exact: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("blocks.appHeader.invite")), 1)
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createVNode(_component_v_btn, {
                  class: "menu-link",
                  color: "primary",
                  to: { name: _unref(ROUTE_ABOUT) },
                  exact: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("blocks.appHeader.about")), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_v_spacer),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(NetworkSelector),
          _createVNode(ToggleDarkMode),
          _createVNode(CurrencySelector),
          _createVNode(WalletConnectBtn, {
            size: "large",
            outlined: ""
          }),
          _createVNode(ActiveAccountBtn)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})