import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mb-2 bcl-page-header" }
const _hoisted_2 = { class: "text-h3 mt-3 mb-6 title" }




export default /*@__PURE__*/_defineComponent({
  __name: 'PageHeader',
  props: {
  title: { type: String, default: null },
  text: { type: String, default: null },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { class: "inner" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createElementVNode("h1", _hoisted_2, [
              _renderSlot(_ctx.$slots, "title", {}, () => [
                _createTextVNode(_toDisplayString(__props.title), 1)
              ])
            ]),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "9",
                  xl: "7",
                  class: "text-subtitle-1"
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "text", {}, () => [
                      _createTextVNode(_toDisplayString(__props.text), 1)
                    ])
                  ]),
                  _: 3
                })
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ]))
}
}

})