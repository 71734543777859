import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import {
  ROUTE_ABOUT,
  ROUTE_ACCOUNT_DETAILS,
  ROUTE_CREATE_TOKEN,
  ROUTE_DAO,
  ROUTE_HOME,
  ROUTE_INVITE,
  ROUTE_SALE,
  ROUTE_TX_QUEUE,
} from "@/utils/constants";

import HomeView from "../views/HomeView.vue";
import TransactionQueue from "../views/TransactionQueue.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: ROUTE_HOME,
    component: HomeView,
  },
  {
    path: "/tx-queue/:id",
    name: ROUTE_TX_QUEUE,
    component: TransactionQueue,
  },
  {
    path: "/sale/:contractAddress",
    name: ROUTE_SALE,
    component: () =>
      import(
        /* webpackChunkName: "token-sale-details" */ "../views/TokenSaleDetails.vue"
      ),
  },
  {
    path: "/sale/:saleAddress/dao",
    name: ROUTE_DAO,
    component: () =>
      import(
        /* webpackChunkName: "treasury-details" */ "../views/Dao/TreasuryView.vue"
      ),
  },
  {
    path: "/sale/:saleAddress/dao/create",
    name: "create-vote",
    component: () =>
      import(
        /* webpackChunkName: "create-vote" */ "../views/Dao/CreateVoteView.vue"
      ),
  },
  {
    path: "/sale/:saleAddress/dao/:voteId/:voteAddress",
    name: "vote-details",
    component: () =>
      import(
        /* webpackChunkName: "vote-details" */ "../views/Dao/VoteView.vue"
      ),
  },
  {
    path: "/create-token",
    name: ROUTE_CREATE_TOKEN,
    component: () =>
      import(
        /* webpackChunkName: "create-token" */ "../views/SelectCreateTokenCategoryView.vue"
      ),
  },
  {
    path: "/create-token/words",
    name: "create-token-word-category",
    component: () =>
      import(
        /* webpackChunkName: "create-token-word-category" */ "../views/CreateTokenCategories/WordTokenCreateView.vue"
      ),
  },
  {
    path: "/create-token/numbers",
    name: "create-token-number-category",
    component: () =>
      import(
        /* webpackChunkName: "create-token-number-category" */ "../views/CreateTokenCategories/NumericTokenCreateView.vue"
      ),
  },
  {
    path: "/invite",
    name: ROUTE_INVITE,
    component: () =>
      import(/* webpackChunkName: "invite" */ "../views/InviteView.vue"),
  },
  {
    path: "/about",
    name: ROUTE_ABOUT,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/accounts/:address",
    name: ROUTE_ACCOUNT_DETAILS,
    component: () =>
      import(
        /* webpackChunkName: "account-details" */ "../views/Accounts/AccountDetails.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
