import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex ga-5 mb-4" }
const _hoisted_2 = { class: "text-body-2" }



import ListItemBullet from "./ListItemBullet.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'OrderedListItem',
  props: {
  index: { type: Number, default: 1 },
  large: Boolean,
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ListItemBullet, {
      index: __props.index,
      size: __props.large ? 'lg' : undefined
    }, null, 8, ["index", "size"]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})