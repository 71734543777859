import { SETTINGS } from "@/utils/constants";

export const createDeepLinkUrl = ({ type, callbackUrl, ...params }: any) => {
  const url = new URL(`${SETTINGS.wallet.url}/${type}`);
  if (callbackUrl) {
    url.searchParams.set("x-success", callbackUrl);
    url.searchParams.set("x-cancel", callbackUrl);
  }
  Object.entries(params)
    .filter(([, value]: any) => ![undefined, null].includes(value))
    .forEach(([name, value]: any) => url.searchParams.set(name, value));
  return url;
};
