<template>
  <div
    class="count-down"
    :class="{
      'd-flex align-center justify-center': !smAndDown,
      'px-4 py-8': smAndDown,
    }"
  >
    <div class="text-uppercase text-center">
      <AppLogo class="logo" />
      <h1 v-text="$t('views.countdown.pageTitle')" class="text-h4 mt-6" />
      <p v-text="$t('common.comingSoon')" class="text-h2 text-primary mt-4" />

      <div
        v-if="SETTINGS.social.x"
        class="social-links mt-12 pt-12 border-t-sm"
      >
        <div v-text="$t('views.countdown.follow')" class="text-h6 mb-2" />
        <a :href="SETTINGS.social.x" target="_blank">
          <TwitterIcon style="width: 22px" />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDisplay } from "vuetify";

import { SETTINGS } from "@/utils/constants";

import AppLogo from "@/components/Common/AppLogo.vue";

import TwitterIcon from "@/assets/logos/twitter-logo.svg";

const { smAndDown } = useDisplay();
</script>

<style lang="scss" scoped>
.count-down {
  height: 100vh;
  width: 100vw;

  .social-links {
    margin-inline: auto;
    max-width: 400px;
  }
}
</style>
