<template>
  <div
    v-if="activeAccount"
    class="active-account-btn d-flex align-center pa-1 rounded-lg"
  >
    <v-btn
      variant="tonal"
      class="px-3"
      :to="{ name: ROUTE_ACCOUNT_DETAILS, params: { address: activeAccount } }"
    >
      <div class="d-flex align-center account-address">
        <AddressAvatar :address="activeAccount" :size="26" />

        <span class="d-none d-lg-inline">
          {{ formatAddress(activeAccount) }}
        </span>
      </div>
    </v-btn>

    <div class="d-flex align-center pl-4 pr-2">
      <v-progress-circular
        v-if="scanningForAccounts || (balanceLoading && !balanceLoaded)"
        size="18"
        width="2"
        indeterminate
      />
      <span v-else>
        {{ smAndDown ? balance?.shorten() : balance?.prettify() }}&nbsp;{{
          AETERNITY_SYMBOL
        }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref } from "vue";
import { useDisplay } from "vuetify";

import { AETERNITY_SYMBOL, ROUTE_ACCOUNT_DETAILS } from "@/utils/constants";
import { formatAddress } from "@/utils/address";
import { useAeppSdk, useWalletConnect } from "@/composables";
import { Decimal } from "@/libs/decimal";
import { useAccounts } from "@/stores/accounts";
import AddressAvatar from "../Common/AddressAvatar.vue";

const { smAndDown } = useDisplay();
const { activeAccount } = storeToRefs(useAccounts());
const { scanningForAccounts } = useWalletConnect();

const { getAeSdk } = useAeppSdk();

const balance = ref(Decimal.ZERO);
const balanceLoading = ref(true);
const balanceLoaded = ref(false);
let timer: NodeJS.Timer;

async function loadBalance() {
  balanceLoading.value = true;
  const sdk = await getAeSdk();
  if (sdk && activeAccount.value) {
    const loadedBalance = await sdk.getBalance(activeAccount.value);
    balance.value = Decimal.fromBigNumberString(loadedBalance);
  } else {
    balance.value = Decimal.ZERO;
  }
  balanceLoaded.value = true;
  balanceLoading.value = false;
}

onMounted(() => {
  timer = setInterval(() => {
    loadBalance();
  }, 5000);
});

onUnmounted(() => {
  clearInterval(timer);
});
</script>

<style lang="scss" scoped>
.active-account-btn {
  background: rgba(white, 0.1);

  .account-address {
    .avatar {
      width: 26px;
      height: 26px;
    }
  }
}
</style>
