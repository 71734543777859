import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-primary" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "d-flex flex-wrap ga-4 mt-6" }

import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";

import { SETTINGS } from "@/utils/constants";
import { useAccounts } from "@/stores/accounts";
import { useInvitation } from "@/composables";

import PageHeader from "@/components/Common/PageHeader.vue";
import WalletConnectBtn from "@/components/WalletConnect/WalletConnectBtn.vue";
import HowItWorksDialog from "@/components/Dialogs/HowItWorksDialog.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'WalletConnectCard',
  setup(__props) {

const { activeAccount } = storeToRefs(useAccounts());
const { smAndDown } = useDisplay();

const { invitationCode } = useInvitation();

return (_ctx: any,_cache: any) => {
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (!_unref(activeAccount) && !_unref(invitationCode))
    ? (_openBlock(), _createBlock(PageHeader, {
        key: 0,
        class: "wallet-connect-card"
      }, {
        title: _withCtx(() => [
          _createVNode(_component_i18n_t, { keypath: "blocks.walletConnectCard.title" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(SETTINGS).wallet.name), 1)
            ]),
            _: 1
          })
        ]),
        text: _withCtx(() => [
          _createElementVNode("p", {
            textContent: _toDisplayString(_ctx.$t('blocks.walletConnectCard.desc1')),
            class: "text-subtitle-1"
          }, null, 8, _hoisted_2),
          _createElementVNode("p", {
            textContent: _toDisplayString(_ctx.$t('blocks.walletConnectCard.desc2')),
            class: "text-subtitle-1"
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(WalletConnectBtn, { block: _unref(smAndDown) }, null, 8, ["block"]),
            _createVNode(HowItWorksDialog)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})