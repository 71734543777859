import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]

import { useDisplay } from "vuetify";
import {
  ROUTE_ABOUT,
  ROUTE_CREATE_TOKEN,
  ROUTE_HOME,
  ROUTE_INVITE,
} from "@/utils/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'BottomNavigation',
  setup(__props) {

const { smAndDown } = useDisplay();

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_bottom_navigation = _resolveComponent("v-bottom-navigation")!

  return (_unref(smAndDown))
    ? (_openBlock(), _createBlock(_component_v_bottom_navigation, {
        key: 0,
        class: "bcl-bottom-navigation",
        grow: "",
        fixed: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            to: { name: _unref(ROUTE_HOME) },
            exact: "",
            color: "primary",
            class: "menu-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                textContent: _toDisplayString(_ctx.$t('blocks.appBottomNav.tokens'))
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_v_btn, {
            to: { name: _unref(ROUTE_CREATE_TOKEN) },
            exact: "",
            color: "primary",
            class: "menu-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                textContent: _toDisplayString(_ctx.$t('blocks.appBottomNav.createToken'))
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_v_btn, {
            to: { name: _unref(ROUTE_INVITE) },
            exact: "",
            color: "primary",
            class: "menu-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                textContent: _toDisplayString(_ctx.$t('blocks.appBottomNav.invite'))
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_v_btn, {
            to: { name: _unref(ROUTE_ABOUT) },
            exact: "",
            color: "primary",
            class: "menu-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                textContent: _toDisplayString(_ctx.$t('blocks.appBottomNav.about'))
              }, null, 8, _hoisted_4)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})