<template>
  <v-menu :disabled="!networkChangeAllowed">
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        variant="text"
        class="active-network bcl-app-header-options"
        :title="$t('common.network')"
        :readonly="!networkChangeAllowed"
      >
        <v-icon color="green" size="14">mdi-circle</v-icon>
        <span class="d-none d-lg-block ml-2">{{ activeNetwork.name }}</span>
        <v-icon v-if="networkChangeAllowed" class="mr-n1">
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <template v-for="network in NETWORKS" :key="network.networkId">
        <v-list-item
          v-if="network.networkId !== activeNetwork.networkId"
          :value="network.networkId"
          @click="changeActiveNetwork(network.networkId)"
        >
          <v-list-item-title>
            {{ network.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { useNetwork } from "@/composables";
import { NETWORKS } from "@/utils/constants";

const { activeNetwork, changeActiveNetwork } = useNetwork();

const networkChangeAllowed = Object.keys(NETWORKS).length > 1;
</script>

<style lang="scss" scoped>
.active-network {
  min-width: 0;
}
</style>
