import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]




export default /*@__PURE__*/_defineComponent({
  __name: 'ListItemBullet',
  props: {
  index: { type: Number, default: 1 },
  size: { type: String, default: "rg" },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    textContent: _toDisplayString(__props.index),
    class: "d-flex flex-shrink-0 align-center justify-center list-item-bullet"
  }, null, 8, _hoisted_1))
}
}

})