<template>
  <div>
    <v-btn
      color="primary"
      variant="text"
      :size="smAndDown ? 'small' : undefined"
      @click="showDialog = true"
    >
      {{ $t("blocks.howItWorks.title") }}
    </v-btn>

    <v-dialog v-model="showDialog" max-width="600px" :fullscreen="smAndDown">
      <v-card>
        <v-card-title v-text="$t('blocks.howItWorks.title')" />

        <v-card-text class="px-4">
          <div
            v-text="$t('blocks.howItWorks.intro')"
            class="font-weight-black mb-6"
          />

          <OrderedListItem
            v-for="({ title, desc }, index) in listItems"
            :key="index"
            :index="index + 1"
          >
            <div v-text="title" class="text-h5" />
            <div v-text="desc" class="text-body-1 flex-wrap" />
          </OrderedListItem>

          <div class="d-flex ga-4 mb-4">
            <div
              v-text="
                $t('blocks.howItWorks.outro', { appName: SETTINGS.app.name })
              "
              class="text-body-1"
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            flat
            color="primary"
            variant="outlined"
            size="large"
            class="px-12"
            @click="showDialog = false"
          >
            {{ $t("common.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify/lib/framework.mjs";

import { SETTINGS } from "@/utils/constants";

import OrderedListItem from "@/components/Common/OrderedListItem.vue";

const { smAndDown } = useDisplay();
const { t } = useI18n();

const showDialog = ref(false);

const listItems = [
  {
    title: t("blocks.howItWorks.listItems.item1.title"),
    desc: t("blocks.howItWorks.listItems.item1.desc"),
  },
  {
    title: t("blocks.howItWorks.listItems.item2.title"),
    desc: t("blocks.howItWorks.listItems.item2.desc"),
  },
  {
    title: t("blocks.howItWorks.listItems.item3.title"),
    desc: t("blocks.howItWorks.listItems.item3.desc"),
  },
  {
    title: t("blocks.howItWorks.listItems.item4.title"),
    desc: t("blocks.howItWorks.listItems.item4.desc"),
  },
  {
    title: t("blocks.howItWorks.listItems.item5.title"),
    desc: t("blocks.howItWorks.listItems.item5.desc"),
  },
  {
    title: t("blocks.howItWorks.listItems.item6.title"),
    desc: t("blocks.howItWorks.listItems.item6.desc"),
  },
];
</script>
