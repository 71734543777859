<template>
  <v-card
    v-if="token?.sale_address"
    block
    flat
    class="px-2 token-card"
    :to="{
      name: ROUTE_SALE,
      params: { contractAddress: token.sale_address },
    }"
  >
    <v-row align="center">
      <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-6 py-8">
        <div class="d-flex align-center">
          <div class="rank">
            #<b>{{ useCategoryRank ? token.category_rank : token.rank }}</b>
          </div>
          <div class="token-name">
            {{ token.symbol }}
          </div>
        </div>
      </v-col>
      <v-col cols="1" sm="1" md="1" lg="1" class="py-0 text-right">
        <CategoryName>
          {{ token.category }}
          <v-progress-circular
            v-if="!token.category"
            indeterminate
            size="20"
            color="primary"
          />
        </CategoryName>
      </v-col>
      <v-col cols="6" sm="3" md="2" lg="1" class="py-0 text-right">
        <PriceDataFormatter
          :price-data="tokenData.price_data"
          class="justify-end"
        />
      </v-col>
      <v-col
        cols="6"
        sm="3"
        md="2"
        lg="1"
        class="py-0 token-market-cap text-right"
      >
        <PriceDataFormatter
          bignumber
          :price-data="tokenData.market_cap_data"
          class="justify-end"
        />
      </v-col>
      <v-col cols="3" md="2" lg="2" xl="1" class="px-0 text-center">
        <AddressChip :address="token.sale_address" link />
      </v-col>
      <v-col cols="12" sm="9" md="1">
        <TokenLineChart
          :sale-address="token.address"
          chart-type="line"
          :height="60"
        />
      </v-col>
      <v-col cols="12" sm="3" md="2" v-if="!mdAndDown">
        <v-btn
          variant="outlined"
          block
          :to="{
            name: ROUTE_SALE,
            params: { contractAddress: token.sale_address },
          }"
        >
          {{ $t("common.viewToken") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";
import { useDisplay } from "vuetify";

import { ROUTE_SALE } from "@/utils/constants";
import { TokenDto } from "@/api/generated";
import { useLiveTokenData } from "@/composables";

import PriceDataFormatter from "@/components/Common/Pricing/PriceDataFormatter.vue";
import TokenLineChart from "@/components/Charts/TokenLineChart.vue";
import AddressChip from "@/components/Common/AddressChip.vue";
import CategoryName from "@/components/Common/CategoryName.vue";

const { mdAndDown } = useDisplay();

const props = defineProps<{
  token: TokenDto;
  useCategoryRank?: boolean;
}>();

const { tokenData } = useLiveTokenData({
  token: props.token,
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables" as *;

.token-card {
  .rank,
  .token-name {
    transition: all 0.2s;
  }

  .rank {
    font-size: 25px;
    opacity: 0.3;
    width: 70px;
  }

  .token-name {
    font-size: 20px;
    line-height: 100%;
  }

  .token-price {
    font-size: 16px;
  }

  .token-market-cap {
    font-size: 16px;
    font-weight: 500;

    .fiat {
      opacity: 0.8;
      font-weight: 400;
    }
  }

  .v-btn {
    width: 162px;

    &--block {
      min-width: auto;
      text-align: right;
      margin: auto;
    }
  }

  &:hover {
    .rank {
      opacity: 0.8;
    }

    .token-name {
      color: rgb(var(--v-theme-primary));
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 1479px) {
    .rank {
      font-size: 18px;
    }
    .token-name {
      font-size: 16px;
    }
    .token-market-cap {
      font-size: 14px;
    }
  }
}
</style>
