import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "active-account-btn d-flex align-center pa-1 rounded-lg"
}
const _hoisted_2 = { class: "d-flex align-center account-address" }
const _hoisted_3 = { class: "d-none d-lg-inline" }
const _hoisted_4 = { class: "d-flex align-center pl-4 pr-2" }
const _hoisted_5 = { key: 1 }

import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref } from "vue";
import { useDisplay } from "vuetify";

import { AETERNITY_SYMBOL, ROUTE_ACCOUNT_DETAILS } from "@/utils/constants";
import { formatAddress } from "@/utils/address";
import { useAeppSdk, useWalletConnect } from "@/composables";
import { Decimal } from "@/libs/decimal";
import { useAccounts } from "@/stores/accounts";
import AddressAvatar from "../Common/AddressAvatar.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ActiveAccountBtn',
  setup(__props) {

const { smAndDown } = useDisplay();
const { activeAccount } = storeToRefs(useAccounts());
const { scanningForAccounts } = useWalletConnect();

const { getAeSdk } = useAeppSdk();

const balance = ref(Decimal.ZERO);
const balanceLoading = ref(true);
const balanceLoaded = ref(false);
let timer: NodeJS.Timer;

async function loadBalance() {
  balanceLoading.value = true;
  const sdk = await getAeSdk();
  if (sdk && activeAccount.value) {
    const loadedBalance = await sdk.getBalance(activeAccount.value);
    balance.value = Decimal.fromBigNumberString(loadedBalance);
  } else {
    balance.value = Decimal.ZERO;
  }
  balanceLoaded.value = true;
  balanceLoading.value = false;
}

onMounted(() => {
  timer = setInterval(() => {
    loadBalance();
  }, 5000);
});

onUnmounted(() => {
  clearInterval(timer);
});

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_unref(activeAccount))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_btn, {
          variant: "tonal",
          class: "px-3",
          to: { name: _unref(ROUTE_ACCOUNT_DETAILS), params: { address: _unref(activeAccount) } }
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(AddressAvatar, {
                address: _unref(activeAccount),
                size: 26
              }, null, 8, ["address"]),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(formatAddress)(_unref(activeAccount))), 1)
            ])
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("div", _hoisted_4, [
          (_unref(scanningForAccounts) || (balanceLoading.value && !balanceLoaded.value))
            ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                key: 0,
                size: "18",
                width: "2",
                indeterminate: ""
              }))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(smAndDown) ? balance.value?.shorten() : balance.value?.prettify()) + " " + _toDisplayString(_unref(AETERNITY_SYMBOL)), 1))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})