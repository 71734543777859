import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "text-h4 py-4 d-flex ga-1 align-center flex-wrap" }
const _hoisted_5 = { class: "pb-4 d-flex ga-1 align-center flex-wrap" }

import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { initCommunityFactory } from "token-gating-sdk";
import {
  AeSdk,
  AeSdkAepp,
  CompilerHttp,
  MemoryAccount,
} from "@aeternity/aepp-sdk";

import { SETTINGS } from "@/utils/constants";
import { Decimal } from "@/libs/decimal";
import { useAccounts } from "@/stores/accounts";
import {
  useAeppSdk,
  useInvitation,
  useNetwork,
  useTokenSaleFactory,
} from "@/composables";

import WalletConnectBtn from "@/components/WalletConnect/WalletConnectBtn.vue";
import AddressChip from "@/components/Common/AddressChip.vue";
import LivePriceFormatter from "@/components/Common/Pricing/LivePriceFormatter.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CollectInvitationLinkCard',
  setup(__props) {

const appName = SETTINGS.app.name;

const router = useRouter();

const { getAeSdk, nodes } = useAeppSdk();
const { activeNetwork } = useNetwork();
const { activeAccount } = storeToRefs(useAccounts());
const { activeTokenSaleFactoryAddress } = useTokenSaleFactory();
const { invitationCode } = useInvitation();

const invitationAmount = ref<Decimal>(Decimal.ZERO);
const invitationSender = ref();
const invitationClaimed = ref(false);

const loadingInvitation = ref(false);
const claimingReward = ref(false);

const errorMessage = ref();
const successMessage = ref();

const isRevoking = computed(
  () => invitationSender.value === activeAccount.value
);

watch(
  invitationCode,
  (invCode) => {
    if (invCode) {
      getInvitationRewardAmount();
    }
  },
  {
    immediate: true,
  }
);

watch(activeNetwork, () => {
  if (invitationCode.value) {
    getInvitationRewardAmount();
  }
});

/**
 * Retrieves the affiliation contract from the room factory.
 *
 * @param {AeSdk | AeSdkAepp} sdk - The AeSdk or AeSdkAepp instance.
 * @returns {Promise<AffiliationTreasury>} The affiliation treasury instance.
 */
async function getAffiliationTreasury(sdk: AeSdk | AeSdkAepp) {
  const factory = await initCommunityFactory(
    sdk,
    activeTokenSaleFactoryAddress.value
  );

  return await factory.affiliationTreasury();
}

/**
 * Retrieves the invitation reward amount for a given invitation code.
 * This function sets the loading state, initializes the AeSdk, retrieves the affiliation contract,
 * and updates the invitation details if the invitation code exists.
 * If the invitation has already been claimed, it displays an error message and closes the component after 3 seconds.
 * @throws {Error} If there is an error retrieving the invitation reward amount.
 */
async function getInvitationRewardAmount() {
  errorMessage.value = null;
  loadingInvitation.value = true;
  try {
    const account = new MemoryAccount(invitationCode.value);
    const sdk = new AeSdk({
      onCompiler: new CompilerHttp("https://v7.compiler.aepps.com"),
      nodes,
      accounts: [account],
    });
    sdk.selectNode(activeNetwork.value.name);

    const affiliationTreasury = await getAffiliationTreasury(sdk);

    const invitationCodes = await affiliationTreasury
      .invitationCodes()
      .catch(() => ({}));

    const invitation = invitationCodes.get(account.address);
    if (invitation) {
      invitationSender.value = invitation[0];
      invitationAmount.value = Decimal.fromBigNumberString(invitation[1]);
      invitationClaimed.value = invitation[2];

      if (invitationClaimed.value) {
        errorMessage.value = "This invitation has already been claimed.";

        setTimeout(() => {
          onClose();
        }, 3000);
      }
    }
  } catch (error: any) {
    if (error?.message?.includes("Trying to call undefined function")) {
      errorMessage.value = "Please switch to the correct network.";
    } else {
      errorMessage.value = error?.message;
    }
  }
  loadingInvitation.value = false;
}

async function claimOrRevokeReward() {
  if (!invitationCode.value || !activeAccount.value) {
    return false;
  }
  claimingReward.value = true;
  const sdk = await getAeSdk();
  const affiliationTreasury = await getAffiliationTreasury(sdk);

  try {
    if (isRevoking.value) {
      const account = new MemoryAccount(invitationCode.value);
      await affiliationTreasury.revokeInvitationCode(account.address);
      successMessage.value = "Reward revoked successfully.";
    } else {
      await affiliationTreasury.redeemInvitationCode(
        invitationCode.value,
        activeAccount.value
      );
      successMessage.value = "Reward claimed successfully.";
    }
    invitationClaimed.value = true;
  } catch (error: any) {
    if (error?.message?.includes("INVITEE_ALREADY_REGISTERED")) {
      errorMessage.value =
        "You can't claim this reward with the current active account, try with another account.";
    } else {
      errorMessage.value = `Failed to claim reward. Reason: ${
        error?.message || "Unknown"
      }`;
    }
  }
  claimingReward.value = false;
}

function onClose() {
  localStorage.removeItem("invite_code");
  router.push({ query: { invite_code: null } });
  invitationCode.value = null;
}

return (_ctx: any,_cache: any) => {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (!!_unref(invitationCode))
    ? (_openBlock(), _createBlock(_component_v_card, {
        key: 0,
        class: "mb-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (!isRevoking.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", {
                      class: "text-h3 pb-4",
                      textContent: _toDisplayString(_ctx.$t('blocks.invitationCollect.header', { appName: _unref(appName) }))
                    }, null, 8, _hoisted_1),
                    _createElementVNode("p", {
                      textContent: _toDisplayString(_ctx.$t('blocks.invitationCollect.desc1'))
                    }, null, 8, _hoisted_2),
                    _createElementVNode("p", {
                      textContent: _toDisplayString(_ctx.$t('blocks.invitationCollect.desc2'))
                    }, null, 8, _hoisted_3)
                  ], 64))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_4, [
                _createVNode(_component_i18n_t, {
                  keypath: 
            isRevoking.value
              ? 'blocks.invitationCollect.revokeReward'
              : 'blocks.invitationCollect.claimReward'
          
                }, {
                  default: _withCtx(() => [
                    _createVNode(LivePriceFormatter, {
                      "ae-price": invitationAmount.value,
                      "watch-price": false,
                      "price-loading": loadingInvitation.value,
                      class: "text-primary",
                      row: ""
                    }, null, 8, ["ae-price", "price-loading"])
                  ]),
                  _: 1
                }, 8, ["keypath"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                (!isRevoking.value)
                  ? (_openBlock(), _createBlock(_component_i18n_t, {
                      key: 0,
                      keypath: "blocks.invitationCollect.addressInvited"
                    }, {
                      address: _withCtx(() => [
                        _createVNode(AddressChip, { address: invitationSender.value }, null, 8, ["address"])
                      ]),
                      appName: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(appName)), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_i18n_t, {
                  keypath: 
            _unref(activeAccount)
              ? 'blocks.invitationCollect.mainMessageConnected'
              : 'blocks.invitationCollect.mainMessageNotConnected'
          
                }, {
                  prize: _withCtx(() => [
                    _createVNode(LivePriceFormatter, {
                      "ae-price": invitationAmount.value,
                      "watch-price": false,
                      "price-loading": loadingInvitation.value,
                      row: ""
                    }, null, 8, ["ae-price", "price-loading"])
                  ]),
                  _: 1
                }, 8, ["keypath"])
              ]),
              (errorMessage.value)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 1,
                    icon: "$error",
                    color: "error",
                    class: "mb-5",
                    border: "start",
                    title: _ctx.$t('common.oops'),
                    text: errorMessage.value
                  }, null, 8, ["title", "text"]))
                : _createCommentVNode("", true),
              (successMessage.value)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 2,
                    icon: "$success",
                    color: "success",
                    class: "mb-5",
                    border: "start",
                    title: "Success!",
                    text: successMessage.value
                  }, null, 8, ["text"]))
                : _createCommentVNode("", true),
              (_unref(activeAccount))
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 3,
                    size: "large",
                    color: "primary",
                    disabled: invitationClaimed.value,
                    loading: claimingReward.value,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (claimOrRevokeReward()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(isRevoking.value
            ? _ctx.$t("blocks.invitationCollect.ctaRevoke")
            : _ctx.$t("blocks.invitationCollect.ctaClaim")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading"]))
                : _createCommentVNode("", true),
              _createVNode(WalletConnectBtn, {
                label: _ctx.$t('blocks.invitationCollect.connectWallet'),
                size: "large"
              }, null, 8, ["label"]),
              (_unref(activeAccount))
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 4,
                    textContent: _toDisplayString(_ctx.$t('common.dismiss')),
                    size: "large",
                    color: "primary",
                    variant: "outlined",
                    class: "ml-4",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (onClose()))
                  }, null, 8, ["textContent"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})