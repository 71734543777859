import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 3,
  class: "bg-green text-copied"
}

import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { Encoding } from "@aeternity/aepp-sdk";

import { useAeScan } from "@/composables";
import { useAccounts } from "@/stores/accounts";
import { formatAddress } from "@/utils/address";
import { validateHash } from "@/utils/common";

import AddressAvatar from "./AddressAvatar.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressChip',
  props: {
    address: {},
    hideAvatar: { type: Boolean },
    link: { type: Boolean },
    copyable: { type: Boolean },
    prefix: {},
    large: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();
const { prepareUrlForHash } = useAeScan();
const { activeAccount } = storeToRefs(useAccounts());

const textCopied = ref(false);

const isAccountAddress = computed(() => {
  const { valid, prefix } = validateHash(props.address);
  return valid && prefix === Encoding.AccountAddress;
});

function onChipClick() {
  if (props.copyable) {
    navigator.clipboard.writeText(props.address);
    textCopied.value = true;

    setTimeout(() => {
      textCopied.value = false;
    }, 1000);
  } else if (isAccountAddress.value) {
    return router.push(`/accounts/${props.address}`);
  } else if (props.link) {
    window.open(prepareUrlForHash(props.address, props.prefix), "_blank");
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_ctx.address)
    ? (_openBlock(), _createBlock(_component_v_chip, _mergeProps({ key: 0 }, _ctx.$attrs, {
        class: ["bcl-address-chip", { 'pa-4': _ctx.large }],
        color: _unref(activeAccount) === _ctx.address ? 'primary' : undefined,
        size: "small",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (onChipClick()), ["prevent"]))
      }), {
        default: _withCtx(() => [
          (!_ctx.hideAvatar)
            ? (_openBlock(), _createBlock(AddressAvatar, {
                key: 0,
                address: _ctx.address
              }, null, 8, ["address"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass({ 'text-large': _ctx.large })
          }, _toDisplayString(_unref(formatAddress)(_ctx.address)), 3),
          (_ctx.link && !isAccountAddress.value)
            ? (_openBlock(), _createBlock(_component_v_avatar, {
                key: 1,
                end: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("mdi-open-in-new")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.copyable)
            ? (_openBlock(), _createBlock(_component_v_avatar, {
                key: 2,
                end: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("mdi-content-copy")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (textCopied.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("common.copied")), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 16, ["class", "color"]))
    : _createCommentVNode("", true)
}
}

})