<template>
  <PriceFormatter :ae-price="aePrice" :fiat-price="fiatPrice" :row="row" />
</template>

<script lang="ts" setup>
import { computed, defineProps, PropType } from "vue";
import { toAe } from "@aeternity/aepp-sdk";

import { PriceDto } from "@/api/generated";
import { useCurrencies } from "@/composables";
import { Decimal } from "@/libs/decimal";

import PriceFormatter from "@/components/Common/Pricing/PriceFormatter.vue";

const props = defineProps({
  priceData: {
    type: Object as PropType<PriceDto>,
    required: true,
  },
  bignumber: Boolean,
  row: Boolean,
});

const { currentCurrencyCode, getFiat } = useCurrencies();

const aePrice = computed(() => {
  if (!props.priceData?.ae) {
    return Decimal.ZERO;
  }
  return props.bignumber
    ? Decimal.from(toAe(props.priceData.ae))
    : Decimal.from(props.priceData.ae);
});

const fiatPrice = computed(() => {
  if (!props.priceData?.ae) {
    return Decimal.ZERO;
  }
  if (!props.priceData[currentCurrencyCode.value]) {
    if (props.priceData["ae"]) {
      return getFiat(
        props.bignumber
          ? Decimal.from(toAe(props.priceData["ae"]))
          : Decimal.from(props.priceData["ae"])
      );
    }

    return Decimal.ZERO;
  }
  return props.bignumber
    ? Decimal.from(toAe(props.priceData[currentCurrencyCode.value]))
    : Decimal.from(props.priceData[currentCurrencyCode.value]);
});
</script>
