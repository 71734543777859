import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


import { SETTINGS } from "@/utils/constants";
import Logo from "../../../setup/assets/logo.svg";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppLogo',
  props: {
  linked: Boolean,
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.linked ? 'RouterLink' : 'div'), {
    to: "/",
    class: "app-logo"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_unref(Logo), {
          alt: _unref(SETTINGS).app.name,
          class: "logo-image d-block",
          style: _normalizeStyle({
          width: _unref(SETTINGS).app.logo.width,
          height: _unref(SETTINGS).app.logo.height,
        })
        }, null, 8, ["alt", "style"])
      ])
    ]),
    _: 3
  }))
}
}

})