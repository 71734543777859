<template>
  <v-app-bar app fixed location="top" class="bcl-app-header px-3 px-sm-4">
    <div class="container d-flex align-center ga-1 ga-lg-2">
      <div class="d-flex align-center">
        <AppLogo class="logo-btn mr-md-3 mr-lg-6" linked />

        <template v-if="!smAndDown">
          <v-btn
            class="menu-link"
            color="primary"
            :to="{ name: ROUTE_HOME }"
            exact
          >
            {{ $t("common.tokenList") }}
          </v-btn>

          <v-btn
            class="menu-link"
            color="primary"
            :to="{ name: ROUTE_CREATE_TOKEN }"
          >
            {{ $t("blocks.appHeader.createToken") }}
          </v-btn>

          <v-btn
            class="menu-link"
            color="primary"
            :to="{ name: ROUTE_INVITE }"
            exact
          >
            {{ $t("blocks.appHeader.invite") }}
          </v-btn>

          <v-btn
            class="menu-link"
            color="primary"
            :to="{ name: ROUTE_ABOUT }"
            exact
          >
            {{ $t("blocks.appHeader.about") }}
          </v-btn>
        </template>
      </div>

      <v-spacer />

      <div class="d-flex align-center ga-1 ga-sm-2">
        <NetworkSelector />
        <ToggleDarkMode />
        <CurrencySelector />
        <WalletConnectBtn size="large" outlined />
        <ActiveAccountBtn />
      </div>
    </div>
  </v-app-bar>
</template>

<script lang="ts" setup>
import { useDisplay } from "vuetify";

import {
  ROUTE_ABOUT,
  ROUTE_CREATE_TOKEN,
  ROUTE_HOME,
  ROUTE_INVITE,
} from "@/utils/constants";

import ActiveAccountBtn from "@/components/Account/ActiveAccountBtn.vue";
import WalletConnectBtn from "@/components/WalletConnect/WalletConnectBtn.vue";
import ToggleDarkMode from "@/components/Header/ToggleDarkMode.vue";
import NetworkSelector from "@/components/Header/NetworkSelector.vue";
import CurrencySelector from "@/components/Header/CurrencySelector.vue";
import AppLogo from "@/components/Common/AppLogo.vue";

const { smAndDown } = useDisplay();
</script>

<style scoped lang="scss">
@use "@/styles/variables" as *;

.bcl-app-header {
  background-color: $color-card-bg-transparent;
  backdrop-filter: blur(8px);
  box-shadow: 2px 0 20px rgba(var(--v-theme-background), 0.9) !important;

  .menu-link {
    font-family: $body-font-family;
    font-size: 17px;
    font-weight: 600 !important;
    letter-spacing: 1px;
  }
}
</style>
