import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = {
  key: 0,
  style: {"margin-top":"5px","font-size":"12px","padding":"0 2px"}
}
const _hoisted_3 = { key: 1 }




export default /*@__PURE__*/_defineComponent({
  __name: 'FractionFormatter',
  props: {
  fractionalPrice: {
    type: Object,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, _toDisplayString(__props.fractionalPrice.number), 1),
    (__props.fractionalPrice.zerosCount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(__props.fractionalPrice.zerosCount), 1))
      : _createCommentVNode("", true),
    (__props.fractionalPrice.significantDigits)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(__props.fractionalPrice.significantDigits), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})