import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex align-center justify-center",
  style: {"height":"100vh","width":"100vw"}
}
const _hoisted_2 = { class: "text-center" }

import { storeToRefs } from "pinia";
import { ref, watch } from "vue";

import AppHeader from "@/components/Header/AppHeader.vue";
import BottomNavigation from "@/components/BottomNavigation.vue";
import CollectInvitationLinkCard from "@/components/Invitation/CollectInvitationLinkCard.vue";
import CountDown from "@/components/CountDown.vue";

import { SETTINGS } from "@/utils/constants";
import WebSocketClient from "@/libs/WebSocketClient";
import { useAppStore } from "@/stores/app";
import { useNetworkStore } from "@/stores/network";
import {
  useAeppSdk,
  useCurrencies,
  useTokenSaleFactory,
  useWalletConnect,
} from "@/composables";
import { OpenAPI } from "@/api/generated";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { darkMode } = storeToRefs(useAppStore());
const { initSdk, staticAeSdk, getSdk } = useAeppSdk();
const { activeNetwork } = storeToRefs(useNetworkStore());
const { checkWalletConnection } = useWalletConnect();
const { loadAeternityData, loadCurrencyRates } = useCurrencies();
const { initTokenSaleFactory } = useTokenSaleFactory();

const loadingApp = ref(true);

watch(
  activeNetwork,
  async (network, prevNetwork) => {
    if (SETTINGS.showCountdown || !network) {
      return;
    }
    OpenAPI.BASE = `${network.apiUrl}`;
    try {
      await Promise.all([loadAeternityData(), loadCurrencyRates()]);
    } catch (e) {
      console.error("Error loading data", e);
    }
    try {
      await initSdk();
      const sdk = await getSdk();
      sdk.selectNode(network.name);
      staticAeSdk.selectNode(network.name);
      checkWalletConnection();
      initTokenSaleFactory();
    } catch (e) {
      //
    }
    loadingApp.value = false;
    if (network?.websocketUrl !== prevNetwork?.websocketUrl) {
      WebSocketClient.disconnect();
      WebSocketClient.connect(network.websocketUrl);
    }
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_unref(SETTINGS).showCountdown)
    ? (_openBlock(), _createBlock(CountDown, { key: 0 }))
    : (loadingApp.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_v_progress_circular, {
              indeterminate: "",
              color: "primary",
              size: "80"
            })
          ])
        ]))
      : (_openBlock(), _createBlock(_component_v_app, {
          key: 2,
          class: "app",
          theme: _unref(darkMode) ? 'dark' : 'light'
        }, {
          default: _withCtx(() => [
            _createVNode(AppHeader),
            _createVNode(_component_v_main, { class: "py-0 px-3 px-sm-4 app-main" }, {
              default: _withCtx(() => [
                _createVNode(CollectInvitationLinkCard),
                _createVNode(_component_router_view)
              ]),
              _: 1
            }),
            _createVNode(BottomNavigation)
          ]),
          _: 1
        }, 8, ["theme"]))
}
}

})