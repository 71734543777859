import { Encoded, toAe } from "@aeternity/aepp-sdk";
import { useAeppSdk } from "./aeppSdk";
import {
  DAO,
  initFallBack,
  TokenSale,
  Vote,
  VoteMetadata,
} from "token-gating-sdk";
import { ref, shallowRef, watch } from "vue";
import ContractWithMethods, {
  ContractMethodsBase,
} from "@aeternity/aepp-sdk/es/contract/Contract";
import { storeToRefs } from "pinia";
import { useAccounts } from "@/stores/accounts";

export interface UseDaoProps {
  tokenSaleAddress: Encoded.ContractAddress;
}

const tokenSaleFactoryRef = shallowRef<TokenSale>();
const tokenInstanceRef = shallowRef<ContractWithMethods<ContractMethodsBase>>();
const dao = shallowRef<DAO>();
const state = ref();
const balance = ref();
const tokenSupply = ref<bigint>();
const userTokenBalance = ref<bigint>();
const tokenMetaInfo = ref<{ symbol: string; decimals: bigint }>();

export function useDao({ tokenSaleAddress }: UseDaoProps) {
  const { getAeSdk } = useAeppSdk();
  const { activeAccount } = storeToRefs(useAccounts());

  watch(
    () => tokenSaleAddress,
    () => {
      init();
    },
    {
      immediate: true,
    }
  );

  async function init() {
    const aeSdk = await getAeSdk();
    if (tokenSaleFactoryRef.value) {
      await updateState();
      return;
    }
    tokenSaleFactoryRef.value = await initFallBack(aeSdk, tokenSaleAddress);
    console.log("tokenSaleFactoryRef=====>", tokenSaleFactoryRef.value);
    console.log("=====");
    tokenInstanceRef.value =
      await tokenSaleFactoryRef.value.tokenContractInstance();
    dao.value = await tokenSaleFactoryRef.value.checkAndGetDAO();
    await updateState();
  }

  async function updateState() {
    tokenSupply.value = await tokenInstanceRef.value
      ?.total_supply()
      .then((res: any) => res.decodedResult);
    tokenMetaInfo.value = await tokenInstanceRef.value
      ?.meta_info()
      .then((res: any) => res.decodedResult);
    state.value = await dao.value?.state();
    balance.value = toAe((await dao.value?.balanceAettos()) || 0);

    if (activeAccount.value) {
      userTokenBalance.value = await tokenInstanceRef.value
        ?.balance(activeAccount.value)
        .then((res: any) => res.decodedResult || 0n);
    }
  }

  async function addVote(metadata: VoteMetadata): Promise<Vote | undefined> {
    const vote = await dao.value?.addVote(metadata);
    await updateState();
    return vote;
  }
  return {
    tokenSaleFactoryRef,
    tokenInstanceRef,
    dao,
    state,
    balance,
    addVote,
    tokenSupply,
    userTokenBalance,
    tokenMetaInfo,

    updateState,
    init,
  };
}
